import React from "react";

function Efficiency() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 85 85"
        >
            <circle cx="42.5" cy="42.5" r="42.5" fill="#5DB0E2"></circle>
            <path
                fill="#fff"
                d="M46.317 67.344h-6.294a.786.786 0 01-.786-.785v-1.912a22.82 22.82 0 01-9.177-3.803l-1.354 1.354a.79.79 0 01-1.113 0l-4.449-4.449a.787.787 0 010-1.11l1.354-1.355a22.818 22.818 0 01-3.8-9.18h-1.912A.786.786 0 0118 45.32v-6.294c0-.435.353-.785.786-.785h1.912a22.82 22.82 0 013.802-9.177l-1.354-1.354a.79.79 0 010-1.114l4.45-4.449a.79.79 0 011.113 0l1.354 1.354a22.75 22.75 0 019.174-3.802v-1.912c0-.435.353-.786.786-.786h6.294c.435 0 .786.353.786.786v1.912A22.82 22.82 0 0156.28 23.5l1.354-1.354a.787.787 0 011.11 0l4.45 4.45a.79.79 0 010 1.113l-1.354 1.354a22.788 22.788 0 013.802 9.177l1.914-.003c.436 0 .786.353.786.786v6.294a.786.786 0 01-.786.786h-1.912a22.82 22.82 0 01-3.802 9.177l1.354 1.354a.79.79 0 010 1.113l-4.449 4.45a.79.79 0 01-1.113 0l-1.355-1.355a22.787 22.787 0 01-9.177 3.802l.003 1.912a.788.788 0 01-.788.788zm-5.506-1.573h4.72v-1.796c0-.391.287-.723.676-.78 3.5-.5 6.849-1.888 9.684-4.014a.788.788 0 011.028.074l1.272 1.272 3.338-3.338-1.272-1.271a.788.788 0 01-.074-1.03 21.204 21.204 0 004.014-9.683.788.788 0 01.78-.676l1.793.002v-4.72h-1.795a.789.789 0 01-.78-.675c-.5-3.5-1.889-6.85-4.014-9.684a.788.788 0 01.074-1.029l1.272-1.272-3.338-3.335-1.272 1.271a.788.788 0 01-1.029.074 21.204 21.204 0 00-9.683-4.013.786.786 0 01-.674-.78v-1.796h-4.72v1.796c0 .39-.287.723-.676.78-3.5.5-6.849 1.888-9.683 4.013a.788.788 0 01-1.03-.074l-1.269-1.271-3.338 3.338 1.272 1.271a.788.788 0 01.074 1.03 21.204 21.204 0 00-4.014 9.683.786.786 0 01-.78.674h-1.795v4.72h1.796c.39 0 .722.287.78.675.5 3.5 1.888 6.849 4.013 9.684a.788.788 0 01-.074 1.029l-1.272 1.27 3.338 3.337 1.272-1.272a.788.788 0 011.029-.074 21.196 21.196 0 009.683 4.014.787.787 0 01.676.78l-.002 1.795z"
            ></path>
            <path
                fill="#fff"
                d="M43.172 60.263c-9.976 0-18.092-8.116-18.092-18.091 0-9.976 8.116-18.092 18.092-18.092 9.975 0 18.091 8.116 18.091 18.092 0 9.975-8.116 18.091-18.091 18.091zm0-34.612c-9.11 0-16.52 7.411-16.52 16.52 0 9.11 7.41 16.52 16.52 16.52 9.109 0 16.52-7.41 16.52-16.52 0-9.109-7.411-16.52-16.52-16.52z"
            ></path>
            <path
                fill="#fff"
                d="M43.171 55.543c-7.373 0-13.372-6-13.372-13.372 0-7.373 6-13.373 13.372-13.373 7.373 0 13.373 6 13.373 13.373 0 7.373-6 13.372-13.373 13.372zm0-25.17c-6.506 0-11.798 5.293-11.798 11.798 0 6.506 5.294 11.798 11.798 11.798 6.507 0 11.799-5.294 11.799-11.798 0-6.507-5.292-11.799-11.799-11.799z"
            ></path>
            <path
                fill="#fff"
                d="M43.171 32.733a.786.786 0 01-.785-.786v-2.36a.786.786 0 111.571 0v2.36c0 .433-.35.786-.786.786zM50.403 35.726a.79.79 0 01-.556-1.343l1.669-1.67a.79.79 0 011.113 0 .79.79 0 010 1.114l-1.669 1.669a.787.787 0 01-.557.23zM55.758 42.957h-2.36a.786.786 0 110-1.572h2.36a.786.786 0 110 1.572zM52.072 51.857a.779.779 0 01-.556-.23l-1.669-1.669a.79.79 0 010-1.113.79.79 0 011.113 0l1.67 1.669a.79.79 0 01-.559 1.343zM43.171 55.544a.786.786 0 01-.785-.786v-2.36a.786.786 0 111.571 0v2.36c0 .433-.35.786-.786.786zM34.271 51.857a.79.79 0 01-.556-1.343l1.67-1.669a.79.79 0 011.113 0 .79.79 0 010 1.113l-1.669 1.67a.787.787 0 01-.558.23zM32.944 42.957h-2.36a.786.786 0 110-1.572h2.36a.786.786 0 110 1.572zM35.94 35.726a.779.779 0 01-.555-.23l-1.67-1.67a.79.79 0 010-1.113.79.79 0 011.114 0l1.669 1.67a.79.79 0 01-.558 1.343zM49.465 42.957h-4.72a.786.786 0 110-1.572h4.72a.786.786 0 110 1.572zM42.588 41.498a.788.788 0 01-.73-.495l-2.563-6.405a.786.786 0 011.46-.585l2.562 6.405a.786.786 0 01-.729 1.08z"
            ></path>
            <path
                fill="#fff"
                d="M43.172 44.53a2.362 2.362 0 01-2.36-2.359 2.362 2.362 0 012.36-2.36 2.362 2.362 0 012.36 2.36 2.362 2.362 0 01-2.36 2.36zm0-3.145a.787.787 0 000 1.572.787.787 0 000-1.572z"
            ></path>
        </svg>
    );
}

export default Efficiency;
