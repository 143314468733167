import React from "react";

function Asthma() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 60 60"
        >
            <path
                stroke="#5DB0E2"
                strokeDasharray="10 10"
                strokeLinecap="square"
                d="M2.5 1.5H56.5V55.5H2.5z"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M31.197 15.201c-2.285-1.152-5.245-1.573-8.64.014-4.226 1.976-5.592 5.336-5.728 8.548-.14 3.27 1.006 6.328 1.738 7.46 1.356 2.096 2.015 4.36 2.182 5.27l.011.063v6.148a.704.704 0 01-1.409 0v-6.018c-.158-.806-.76-2.832-1.967-4.698-.889-1.374-2.114-4.72-1.963-8.285.154-3.62 1.74-7.52 6.539-9.764 3.824-1.788 7.24-1.323 9.871.005 2.599 1.31 4.442 3.463 5.2 5.107.437.947 1.337 3.381 1.44 5.613.055 1.164.644 2.662 1.245 4.049l.221.506c.212.482.411.937.556 1.314a5.1 5.1 0 01.23.732c.025.113.044.24.044.37 0 .125-.018.304-.112.482-.185.352-.497.54-.764.642a2.928 2.928 0 01-.811.164 7.119 7.119 0 01-.608.015v4.474a2.113 2.113 0 01-2.113 2.113h-3.852v3.178a.704.704 0 01-1.409 0v-4.587h5.261c.39 0 .704-.315.704-.704v-5.9h.705c.145 0 .285.006.425.01.256.009.508.018.779-.003.15-.012.266-.031.35-.055a4.838 4.838 0 00-.145-.427c-.131-.343-.31-.752-.516-1.221l-.237-.543c-.583-1.345-1.291-3.079-1.36-4.544-.092-1.975-.913-4.22-1.312-5.088-.623-1.351-2.237-3.27-4.555-4.44z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M31.212 27.15a.704.704 0 01.983-.164l2.465 1.761a.705.705 0 01-.138 1.224l-.78.324 1.559.877a.704.704 0 01-.69 1.228l-2.818-1.585a.705.705 0 01.074-1.264l.944-.394-1.435-1.024a.704.704 0 01-.164-.983zM27.841 32.472a.704.704 0 01.688-.721l3.029-.07a.704.704 0 01.623 1.06l-.428.728 1.772-.234a.704.704 0 01.184 1.397l-3.205.423A.705.705 0 0129.805 34l.52-.88-1.763.04a.704.704 0 01-.72-.687zM45.868 37.06c.017.165-.004.376-.06.584-.06.214-.14.364-.196.432a.704.704 0 00.13 1.016c.008.008.031.037.064.096.054.096.106.227.13.373.045.268.004.592-.337.914-.548.52-1.107.335-1.281.22a.704.704 0 00-1.04.329c-.03.072-.21.315-.515.438-.244.099-.592.132-1.05-.206-.355-.26-.432-.616-.313-1.072.13-.496.463-.938.677-1.096a.704.704 0 00.158-.968l-.366-.525a.705.705 0 00.094-1.38l-1.546-.414a.704.704 0 00-.76 1.083l1.086 1.559c-.306.379-.571.87-.706 1.385-.215.822-.12 1.855.84 2.563.857.63 1.722.657 2.415.377.356-.145.663-.369.896-.618.651.17 1.557.129 2.38-.652.725-.687.869-1.503.758-2.169a2.581 2.581 0 00-.316-.873c.064-.144.115-.292.156-.438.093-.341.142-.73.103-1.103-.04-.39-.242-.741-.49-1.005a2.273 2.273 0 00-.999-.615c-.849-.254-1.837-.012-2.667.934a.704.704 0 001.059.93c.512-.584.946-.591 1.204-.514a.865.865 0 01.376.23.58.58 0 01.095.13.2.2 0 01.02.055z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Asthma;
