import React from "react";

function RareDiseases() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 55 55"
        >
            <path
                stroke="#5DB0E2"
                strokeDasharray="10 10"
                strokeLinecap="square"
                d="M0.5 0.5H54.5V54.5H0.5z"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M27.769 19.052a9.128 9.128 0 100 18.257 9.128 9.128 0 000-18.257zM17.03 28.181c0-5.93 4.808-10.738 10.738-10.738S38.507 22.25 38.507 28.18c0 5.93-4.808 10.737-10.738 10.737S17.03 34.111 17.03 28.181zM20.163 14.417c-.796 0-1.442.553-1.442 1.236 0 .683.646 1.236 1.442 1.236.796 0 1.442-.553 1.442-1.236 0-.683-.646-1.236-1.442-1.236zm-3.132 1.236c0-1.482 1.402-2.684 3.132-2.684 1.73 0 3.132 1.202 3.132 2.684 0 1.483-1.402 2.685-3.132 2.685-1.73 0-3.132-1.202-3.132-2.685z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M34.48 39.913a1.442 1.442 0 100-2.884 1.442 1.442 0 000 2.884zm3.132-1.442a3.132 3.132 0 11-6.264 0 3.132 3.132 0 016.264 0zM30.049 14.46a1.374 1.374 0 10-1.697 2.16 1.374 1.374 0 001.697-2.16zm-3.195-.763a2.983 2.983 0 114.692 3.685 2.983 2.983 0 01-4.692-3.685zM24.67 42.073a1.374 1.374 0 101.698-2.16 1.374 1.374 0 00-1.697 2.16zm3.195.763a2.983 2.983 0 11-4.692-3.686 2.983 2.983 0 014.692 3.686zM37.817 17.53a1.374 1.374 0 10-2.537 1.055 1.374 1.374 0 002.537-1.056zm-2.415-2.227a2.983 2.983 0 112.293 5.508 2.983 2.983 0 01-2.293-5.508zM17.439 37.726a1.374 1.374 0 102.536-1.055 1.374 1.374 0 00-2.536 1.055zm2.415 2.227a2.983 2.983 0 11-2.293-5.51 2.983 2.983 0 012.293 5.51zM41.91 25.904a1.374 1.374 0 10-2.54-1.047 1.374 1.374 0 002.54 1.047zm-.132-3.282a2.983 2.983 0 11-2.275 5.517 2.983 2.983 0 012.275-5.517zM13.472 29.341a1.374 1.374 0 102.54 1.047 1.374 1.374 0 00-2.54-1.047zm.133 3.282a2.983 2.983 0 112.274-5.516 2.983 2.983 0 01-2.274 5.516zM42.049 35.287a1.374 1.374 0 10-1.583-2.246 1.374 1.374 0 001.583 2.246zm1.648-2.841a2.983 2.983 0 11-4.879 3.436 2.983 2.983 0 014.879-3.436zM12.888 20.483a1.374 1.374 0 101.582 2.246 1.374 1.374 0 00-1.582-2.246zm-1.648 2.84a2.983 2.983 0 114.878-3.435 2.983 2.983 0 01-4.878 3.436zM20.814 17.424l1.432 2.48-1.394.805-1.432-2.48 1.394-.805zm2.096 2.097l-1.432-2.48 1.394-.806 1.432 2.481-1.394.805z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M34.058 36.634l-.353-.611 1.394-.805.353.611-1.394.805zm-1.017-.228l.353.612-1.394.804-.353-.611 1.394-.805zM28.512 17.396l.024-.173-1.593-.228-.253 1.767 3.946.565.253-1.767-1.593-.228-.025.173-.76-.108zM26.219 39.056l.204-1.428 1.594.228-.205 1.428-1.593-.228zm-.555-1.536l-.204 1.428-1.594-.229.205-1.428 1.593.229zM35.108 19.253l-1.252 1.636-1.278-.977 1.25-1.636 1.28.977zm-.642 2.102l1.25-1.636 1.28.978-1.252 1.636-1.278-.978zM20.218 35.911l.658-.861 1.28.978-.66.86-1.278-.977zm.05-1.327l-.66.861-1.278-.977.659-.862 1.278.978zM38.852 25.197l-1.991.265-.213-1.595 1.992-.266.212 1.596zm-1.89 1.026l1.992-.266.213 1.595-1.992.266-.213-1.596zM16.629 30.035l1.215-.162.212 1.595-1.214.163-.213-1.596zm1.113-.922l-1.214.162-.213-1.596 1.214-.162.213 1.596zM39.546 32.91l-2.967-1.373.676-1.46 2.967 1.373-.676 1.46zm-3.289-.677l2.967 1.374-.676 1.46-2.967-1.373.676-1.46zM15.148 22.747l3.275 1.515-.676 1.461-3.275-1.516.676-1.46zm3.597.82L15.47 22.05l.676-1.461 3.275 1.516-.676 1.46zM27.62 23.154a1.798 1.798 0 10-3.32 1.382 1.798 1.798 0 003.32-1.382zm-2.97-2.455a3.408 3.408 0 112.62 6.292 3.408 3.408 0 01-2.62-6.292zM29.92 28.818a.862.862 0 10-1.591.662.862.862 0 001.592-.662zm-1.745-1.95a2.472 2.472 0 111.9 4.563 2.472 2.472 0 01-1.9-4.564zM23.858 29.057a.478.478 0 10-.882.367.478.478 0 00.882-.367zm-1.243-1.744a2.087 2.087 0 111.604 3.854 2.087 2.087 0 01-1.604-3.854z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default RareDiseases;
