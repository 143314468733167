import React from "react";

function Migraine() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 62 62"
        >
            <path
                stroke="#5DB0E2"
                strokeDasharray="10 10"
                strokeLinecap="square"
                d="M2.5 3.5H56.5V57.5H2.5z"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M23.834 26.484c.01 2.713 1.207 4.425 2.698 5.501 1.53 1.105 3.39 1.546 4.618 1.607.413.02.731.374.711.791a.751.751 0 01-.783.719c-1.447-.07-3.602-.576-5.416-1.886-1.848-1.334-3.324-3.494-3.324-6.764v-.031l.003-.031c.093-1.14.568-2.816 1.703-4.224 1.16-1.437 2.984-2.563 5.67-2.563 2.689 0 4.49 1.127 5.617 2.573 1.101 1.414 1.53 3.095 1.593 4.234v.019a5.086 5.086 0 01-1.126 3.268c-.911 1.117-2.412 1.934-4.684 1.934h-.052l-.052-.008a5.835 5.835 0 01-2.666-1.141c-.917-.72-1.675-1.841-1.675-3.44v-.011a3.57 3.57 0 01.61-1.919c.478-.688 1.273-1.218 2.436-1.218 1.166 0 1.95.534 2.403 1.242.423.662.53 1.426.51 1.938a.751.751 0 01-.78.723.753.753 0 01-.715-.787c.012-.286-.058-.718-.272-1.052-.184-.288-.5-.552-1.146-.552-.649 0-.998.268-1.213.576a2.05 2.05 0 00-.337 1.065c.001 1.045.475 1.752 1.097 2.241a4.363 4.363 0 001.906.83c1.85-.012 2.89-.665 3.476-1.383a3.569 3.569 0 00.785-2.25c-.05-.892-.4-2.252-1.274-3.373-.851-1.093-2.225-1.997-4.44-1.997-2.216 0-3.622.905-4.51 2.007a6.555 6.555 0 00-1.37 3.362z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M34.466 16.813c-2.547-1.268-5.846-1.732-9.63.015-4.71 2.175-6.233 5.875-6.385 9.412-.155 3.599 1.121 6.966 1.938 8.213 1.51 2.308 2.245 4.8 2.431 5.801l.013.07v6.768a.78.78 0 01-.785.776.78.78 0 01-.785-.776v-6.625c-.177-.888-.848-3.117-2.193-5.172-.99-1.513-2.357-5.198-2.188-9.121.172-3.986 1.94-8.28 7.288-10.75 4.263-1.97 8.07-1.457 11.003.004 2.896 1.443 4.95 3.813 5.796 5.624.486 1.043 1.49 3.722 1.605 6.179.06 1.282.718 2.93 1.388 4.458l.246.557c.236.53.458 1.032.62 1.447.108.28.203.559.256.806.027.124.048.264.048.407 0 .137-.02.334-.124.531-.206.387-.554.594-.852.707-.298.112-.62.159-.904.18-.214.017-.452.02-.677.017v4.925c0 1.285-1.054 2.327-2.355 2.327h-4.294v3.5a.78.78 0 01-.785.775.78.78 0 01-.785-.776v-5.05h5.864a.78.78 0 00.785-.776v-6.494h.785c.161 0 .318.005.474.01.285.01.566.019.868-.004.167-.013.297-.034.39-.06a5.238 5.238 0 00-.161-.47 32.577 32.577 0 00-.576-1.345l-.264-.597c-.65-1.481-1.44-3.39-1.515-5.003-.103-2.175-1.018-4.647-1.463-5.602-.695-1.488-2.493-3.6-5.077-4.888z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Migraine;
