import React from "react";

function MainImg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 455 308"
        >
            <path
                fill="#DFEEFA"
                d="M18.405 232.006C-42.023 122.016 54.453-3.211 189.781 2.167c40.793 1.622 81.02 16.268 111.161 55.71 69.742 91.26 132.37 69.112 150.757 140.818 18.389 71.706-66.629 118.084-173.794 65.312-66.835-9.228-161.949 73.621-230 9.499-6.905-6.506-23.54-30.65-29.5-41.5z"
            ></path>
            <path
                fill="#B3D3F5"
                d="M123.044 196.196c-6.81 12.069-5.449 22.03-3.197 28.16-5.521-6.299-11.103-12.023-16.397-17.086 13.67-22.111-2.419-32.59-2.419-32.59-6.382 11.809-5.13 21.503-2.897 27.632l-.072-.068a321.34 321.34 0 00-20.424-17.047c10.228-25.243-8.165-32.701-8.165-32.701-4.372 13.98-.28 23.754 3.394 29.112-6.503-4.798-11.438-8.054-13.72-9.519-15.529-16.611-32.673-10.205-32.673-10.205 12.487 17.179 24.786 14.781 30.266 12.428 1.787 1.128 7.091 4.56 14.378 9.943-18.591-1.52-27.309 11.046-27.309 11.046 20.551 7.094 29.221-2.372 32.21-7.351a316.966 316.966 0 0119.946 16.673c.74.672 1.493 1.367 2.249 2.07-18.93-6.191-30.574 5.155-30.574 5.155 20.335 11.76 30.753 1.845 33.833-2.083 5.554 5.321 11.421 11.381 17.188 18.062-18.244-5.824-29.686 4.924-29.686 4.924 18.924 11.409 29.425 3.478 33.225-.723 12.088 14.681 23.351 32.065 29.857 51.149a1.588 1.588 0 002.463.745c.529-.401.77-1.108.545-1.769-6.703-19.657-18.313-37.509-30.731-52.526 14.988-22.399-1.288-33.432-1.288-33.432l-.002.001zM180.28 128.348c-13.791 14.243-15.536 28.551-14.659 37.873-5.328-10.75-10.948-20.728-16.413-29.682 26.912-25.659 8.52-46.067 8.52-46.067-13.105 14.039-14.904 27.936-14.052 37.25l-.075-.121c-7.675-11.891-15.291-22.408-21.985-31.1 23.3-31.26.629-48.322.629-48.322-11.12 17.785-9.03 32.83-5.908 41.602-7.229-9.029-12.855-15.347-15.472-18.211-15.388-28.707-41.38-26.092-41.38-26.092C70.468 73.86 88.315 75.03 96.735 73.77c2.054 2.218 8.127 8.914 16.224 19.042-25.104-8.904-41.708 5.332-41.708 5.332 25.782 17.347 41.192 7.392 47.127 1.581a454.801 454.801 0 0121.463 30.407 386.927 386.927 0 012.351 3.692c-23.873-15.503-44.072-4.028-44.072-4.028 23.786 23.738 41.772 13.798 47.452 9.478 5.728 9.408 11.621 19.955 17.15 31.325-23.061-14.743-42.763-4.025-42.763-4.025 21.966 22.736 39.345 15.578 46.119 11.143 11.341 24.774 20.56 52.992 22.595 81.828a2.27 2.27 0 003.128 1.933 2.266 2.266 0 001.396-2.252c-2.098-29.704-11.626-58.698-23.3-84.057 28.834-25.577 10.386-46.822 10.386-46.822h-.003z"
            ></path>
            <g clipPath="url(#clip0_0_1842)">
                <path
                    fill="#A5CFF2"
                    d="M287.57 299.001c83.71 0 151.571-8.543 151.571-19.081 0-10.539-67.861-19.081-151.571-19.081S136 269.381 136 279.92c0 10.538 67.86 19.081 151.57 19.081z"
                ></path>
                <path
                    fill="#FF9283"
                    d="M180.342 267.988h-7.275v3.653h7.275v-3.653zM216.336 266.867h-7.276v4.775h7.276v-4.775zM201.702 68.521h-11.859v8.883h11.859V68.52z"
                ></path>
                <path
                    fill="#101030"
                    d="M218.297 174.001c-.033 2.94-.158 5.869-.398 8.776l-1.567 19.1a85.331 85.331 0 00-.115 12.385c.926 14.6 1.657 53.34.913 53.54-.992.265-6.218.166-8.326.176a1.017 1.017 0 01-1.01-.874l-12.748-90.83c-.193-.15-.4-.24-.638-.229-.296.016-.536.181-.74.405l-2.935 15.426a104.33 104.33 0 00-1.016 6.411l-2.321 18.219a53.77 53.77 0 00-.432 6.804v5.261c0 8.116-.928 16.204-2.767 24.109l-3.373 14.5a1.033 1.033 0 01-1.007.798h-6.745c-.531 0-.975-.4-1.03-.925l-1.947-18.829a98.936 98.936 0 01-.527-10.182v-24.405c0-1.084.043-2.167.124-3.248l2.723-36.13 1.305-17.336.809-10.725a2.576 2.576 0 012.568-2.378l36.768-.058c1.302-.001 1.946-.264 2.426.942l.209 1.683c.407 3.359.772 6.754 1.066 10.166.502 5.799.797 11.646.73 17.448h.001z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M217.03 105.963c.365 8.667.371 17.348.023 26.013-.028.67-.055 1.341-.088 2.01a315.53 315.53 0 01-.681 10.689c0-.217-.829-.256-1.826-.224-1.398.045-3.13.229-3.377.254-1.351.139-2.676.401-3.989.705-1.707.396-3.398.865-5.119 1.236-4.875 1.051-9.9.554-14.809.04-2.165-.227-4.334-.448-6.512-.515-.523-.016-6.361.121-6.361.377a106.722 106.722 0 01-.531-28.888c.088-.741.59-2.117-.192-2.192-.011-.001.001-.02.001-.025-.082.282-4.14 30.587-1.886 44.961l-3.955.96-1.898.461c-.774.187-.943.39-1.355-.249-.662-1.027-.986-2.519-1.413-3.663a165.287 165.287 0 01-5.734-18.761c-2.558-10.58-4.057-21.671-1.845-32.329 2.214-10.657 8.641-20.876 18.518-25.51 5.512-2.584 11.734-3.31 17.824-3.21 5 .079 10.142.672 15.066.17.045-.003.09-.008.137-.013a21.8 21.8 0 00.453-.052c1.025.212 2.037.469 3.036.773a29.83 29.83 0 014.033 1.538c7.156 3.356 12.502 9.645 15.745 16.906-3.628 3.944-8.135 7.023-13.265 8.536v.002z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M230.294 137.222c-4.878 1.731-.179.936-6.765-.825-.218-.058-6.512-3.779-6.528-4-.748-10.07-1.975-17.599-2.019-17.746l.001.024c.071.744-.279 1.452-.191 2.194 1.081 9.157-3.153 10.594-4.458 19.72-.06.435-2.269 1.189-2.337 1.623 0-.257-1.317 1.184-1.841 1.2-.27.008.075 5.025-.087 4.81-4.543-6.069.34.332.274.335l.547-66.463.59.117c1.025.212 2.037.469 3.036.773 1.376.416 2.725.925 4.033 1.538 4.911 2.301 9.767 5.313 13.275 9.514 3.518 4.213 5.466 9.806 6.566 15.107 2.212 10.659 4.944 28.87-4.096 32.08v-.001z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M205.477 80.268h-19.222c-1.375 0-2.204-1.518-1.457-2.67l1.488-2.29a4.546 4.546 0 013.814-2.069h11.288c1.587 0 3.057.826 3.882 2.177l1.53 2.51c.627 1.028-.116 2.343-1.321 2.343h-.002z"
                ></path>
                <path
                    fill="#101030"
                    d="M201.256 219.255l.118 7.593c.026 1.655.01 3.311-.067 4.964a48.206 48.206 0 00.887 11.693l.243 1.221c1.03 5.148 3.272 11.12 4.298 14.838l1.218-.19 8.214-8.367-14.912-31.752h.001z"
                ></path>
                <path
                    fill="#20204C"
                    d="M180.606 165.706c0 4.854-2.981 8.788-6.659 8.788a5.166 5.166 0 01-1.532-.234l1.305-17.336c.076-.004.152-.005.227-.005 3.678 0 6.659 3.934 6.659 8.787zM218.297 174.001c-3.265-.084-6.307-3.261-7.059-7.599-.829-4.783 1.435-9.167 5.059-9.791a5.12 5.12 0 011.27-.058c.502 5.799.797 11.646.73 17.448zM208.627 41.16c-1.091-.32-2.235-.798-3.389-.787-.576.007-1.147.105-1.724.1a13.6 13.6 0 01.871-.352c.289-.106.575-.242.883-.287.555-.083 1.218-.04 1.738.21.17.081.327.19.482.298.383.27.761.544 1.139.819z"
                ></path>
                <path
                    fill="#20204C"
                    d="M208.849 39.773c-1.138-.042-2.365-.223-3.48.072-.557.148-1.086.385-1.645.522.246-.194.498-.38.756-.555.255-.174.497-.376.785-.496.518-.218 1.17-.339 1.736-.225.185.037.365.104.542.17.437.167.873.34 1.308.512h-.002z"
                ></path>
                <path
                    fill="#20204C"
                    d="M203.613 41.08c.334-1.084.826-2.22.828-3.368 0-.574-.093-1.143-.081-1.718.125.287.24.578.344.871.103.29.236.576.279.883.077.554.025 1.215-.23 1.73a3.423 3.423 0 01-.306.477c-.274.378-.554.752-.833 1.126l-.001-.001z"
                ></path>
                <path
                    fill="#FF9F8F"
                    d="M184.389 59.52c.911-.198 1.327-1.83.929-3.644-.397-1.814-1.458-3.124-2.368-2.927-.911.198-1.327 1.83-.929 3.644.397 1.814 1.458 3.125 2.368 2.927zM208.263 56.6c.397-1.815-.019-3.446-.929-3.644-.911-.198-1.972 1.113-2.369 2.927-.398 1.814.018 3.445.929 3.643.911.198 1.971-1.112 2.369-2.926z"
                ></path>
                <path
                    fill="#FF9F8F"
                    d="M206.286 56.609c0 7.152-4.968 15.374-11.098 15.374-6.129 0-10.593-7.411-11.06-14.281.05.006.1.005.148-.007.411-.089.614-.762.452-1.501-.101-.461-.319-.839-.569-1.037.618-6.466 5.319-11.494 11.029-11.494 6.13 0 11.098 5.796 11.098 12.947v-.001z"
                ></path>
                <path
                    fill="#FF9283"
                    d="M184.089 56.609c0 .361.013.726.039 1.092-.371-.046-.749-.532-.891-1.184-.162-.74.039-1.413.451-1.502.156-.034.318.021.471.141-.046.477-.07.962-.07 1.453zM206.191 56.609c0 .361-.012.726-.038 1.092.37-.046.749-.532.891-1.184.162-.74-.04-1.413-.451-1.502-.156-.034-.319.021-.471.141.046.477.069.962.069 1.453z"
                ></path>
                <path
                    fill="#101030"
                    d="M208.862 45.31a51.395 51.395 0 01-2.768 11.622c.139-.38-.208-1.356-.277-1.761a32.066 32.066 0 00-.367-1.844c-.281-1.2-.654-2.387-1.214-3.49a10.975 10.975 0 00-2.101-2.864v-.002a10.32 10.32 0 00-1.215-1.018c-1.824-1.305-4.371-1.985-6.621-1.53-2.699.544-5.393 2.153-7.151 4.27-.996 1.2-1.641 2.643-2.097 4.122-.226.735-.374 1.483-.565 2.226-.078.305-.139.666-.296.979-.128.257-.101.56-.101.862 0-2.24-1.527-4.161-2.353-6.26-.827-2.097-1.407-4.413-.839-6.596.46-1.769 1.633-3.275 2.956-4.54 3.781-3.619 9.109-5.656 14.332-5.217 1.506.127 3.027.463 4.313 1.257.495.304.957.683 1.258 1.177.625 1.023.463 2.322.279 3.506.874.062 1.757.157 2.578.466.819.309 1.579.858 1.979 1.635.465.908.388 1.986.268 2.998l.002.001z"
                ></path>
                <path
                    fill="#20204C"
                    d="M202.064 46.97c-1.163-1.307-2.77-2.164-4.488-2.484-1.72-.32-3.52-.115-5.168.47a11.531 11.531 0 00-4.942 3.419c-.899 1.07-1.592 2.298-2.112 3.59-.258.64-.475 1.296-.658 1.962-.179.65-.226 1.422-.476 2.035-.166-.637-.017-1.492.075-2.14a11.89 11.89 0 01.529-2.194 13.1 13.1 0 012.1-3.846c.693-.881 1.497-1.684 2.439-2.294 1.147-.742 2.47-1.18 3.808-1.463 1.621-.343 3.383-.472 5.02-.036 1.601.425 3.14 1.465 3.871 2.98h.002zM198.362 39.022c-1.723.201-3.555.414-5.166 1.087-.375.155-.736.343-1.104.514-.524.246-1.041.508-1.539.805-.398.237-.832.449-1.208.72.858-.617 1.686-1.275 2.585-1.831a9.277 9.277 0 012.254-.983c.771-.23 1.607-.454 2.414-.489a6.14 6.14 0 011.764.177zM192.125 42.94c-1.325.507-2.734 1.045-3.875 1.902-.266.198-.515.42-.771.63-.366.3-.723.61-1.057.946-.267.268-.568.523-.812.814.556-.66 1.078-1.35 1.678-1.971a7.565 7.565 0 011.588-1.233c.566-.337 1.182-.682 1.815-.873.464-.14.95-.213 1.434-.214zM185.849 44.18c-.836.376-1.724.776-2.428 1.372-.164.138-.316.29-.473.435a9.18 9.18 0 00-.646.651c-.163.183-.347.36-.493.555.333-.447.645-.911 1.008-1.335a4.9 4.9 0 01.978-.855c.352-.239.736-.485 1.137-.632.294-.108.605-.172.917-.191zM192.125 37.943c-1.639.57-3.38 1.172-4.806 2.177-.333.232-.645.493-.966.74-.458.353-.906.721-1.327 1.117-.336.317-.714.62-1.023.964.704-.785 1.369-1.608 2.125-2.345a9.268 9.268 0 011.987-1.447c.703-.39 1.47-.79 2.251-.998a6.126 6.126 0 011.76-.208h-.001z"
                ></path>
                <path
                    fill="#101030"
                    d="M173.445 269.082h6.309c1.088 0 1.97.88 1.97 1.962v8.875h-10.249v-8.875c0-1.083.883-1.962 1.97-1.962zM168.225 274.106h3.249v5.813h-7.854v-1.226c0-2.532 2.063-4.587 4.605-4.587zM217.933 279.92h-10.249v-8.875c0-1.083.884-1.962 1.97-1.962h6.309c1.088 0 1.97.88 1.97 1.962v8.875z"
                ></path>
                <path
                    fill="#101030"
                    d="M225.786 279.92h-7.854v-5.813h3.249c2.542 0 4.605 2.055 4.605 4.587v1.226z"
                ></path>
                <path
                    fill="#fff"
                    d="M163.594 159.527l.966 3.292 7.001-3.246-.491-6.271-7.476 6.225z"
                ></path>
                <path
                    fill="#101030"
                    d="M173.567 115.795a.328.328 0 01-.322-.392l4.35-21.284a.328.328 0 01.644.13l-4.35 21.284a.33.33 0 01-.322.262zM215.013 112.54a.33.33 0 01-.324-.276l-2.925-18.11a.328.328 0 01.65-.103l2.924 18.109a.328.328 0 01-.325.38z"
                ></path>
                <path
                    fill="#63AFFA"
                    d="M151.286 199.457c7.111 6.339 18.041 5.743 24.418-1.332l93.328-103.549-25.765-22.97-93.31 103.528c-6.385 7.083-5.789 17.976 1.33 24.323h-.001z"
                ></path>
                <path
                    fill="#F7F7F7"
                    d="M154.788 195.566c5.31 4.735 13.472 4.289 18.235-.995l93.992-104.283-19.241-17.155-93.978 104.269c-4.769 5.29-4.324 13.424.992 18.164z"
                ></path>
                <path
                    fill="#63AFFA"
                    d="M252.688 54.727l32.404 28.889a5.103 5.103 0 01.396 7.23l-6.651 7.38a5.16 5.16 0 01-7.26.395l-32.404-28.888a5.103 5.103 0 01-.396-7.23l6.651-7.38a5.161 5.161 0 017.26-.396z"
                ></path>
                <path
                    fill="#F0626E"
                    d="M154.722 192.491l3.143 2.801c3.564 3.177 9.041 2.878 12.236-.668l46.682-51.793a4.284 4.284 0 00-.332-6.072l-9.622-8.579a4.332 4.332 0 00-6.095.333l-46.678 51.788a8.601 8.601 0 00.666 12.19z"
                ></path>
                <path
                    fill="#FF7682"
                    d="M154.435 183.247c-1.112 2.69-.616 5.734 1.486 7.608l3.093 2.757c2.744 2.447 7.192 1.968 9.928-1.069l39.981-44.359-2.805-2.501-37.185 41.257c-2.737 3.037-7.183 3.517-9.927 1.072l-3.094-2.759a6.05 6.05 0 01-1.478-2.007l.001.001z"
                ></path>
                <path
                    fill="#F7F7F7"
                    d="M273.226 96.156a1.194 1.194 0 001.678-.09l7.999-8.876a1.179 1.179 0 00-.092-1.67 1.194 1.194 0 00-1.678.09l-7.999 8.875a1.179 1.179 0 00.092 1.671zM269.227 92.59a1.194 1.194 0 001.678-.09l7.999-8.876a1.179 1.179 0 00-.092-1.67 1.194 1.194 0 00-1.678.09l-7.999 8.875a1.179 1.179 0 00.092 1.672zM265.227 89.025a1.194 1.194 0 001.678-.092l7.999-8.875a1.179 1.179 0 00-.092-1.67 1.194 1.194 0 00-1.678.09l-7.999 8.876a1.179 1.179 0 00.092 1.67zM261.228 85.459a1.194 1.194 0 001.678-.091l7.999-8.875a1.179 1.179 0 00-.092-1.672 1.194 1.194 0 00-1.678.091l-7.999 8.876a1.179 1.179 0 00.092 1.67zM257.228 81.893a1.194 1.194 0 001.678-.091l7.999-8.876a1.179 1.179 0 00-.092-1.67 1.194 1.194 0 00-1.678.09l-7.999 8.876a1.179 1.179 0 00.092 1.67zM253.228 78.328c.489.435 1.24.394 1.679-.091l7.999-8.875a1.179 1.179 0 00-.092-1.671 1.194 1.194 0 00-1.678.09l-7.999 8.876a1.18 1.18 0 00.091 1.67zM249.228 74.762c.489.435 1.24.394 1.679-.091l7.999-8.875a1.179 1.179 0 00-.092-1.672 1.194 1.194 0 00-1.678.092l-7.999 8.875a1.179 1.179 0 00.091 1.67zM245.229 71.196c.489.436 1.24.394 1.678-.091l8-8.875a1.179 1.179 0 00-.092-1.671 1.194 1.194 0 00-1.678.09l-7.999 8.876a1.179 1.179 0 00.091 1.67zM241.229 67.63c.489.436 1.24.394 1.678-.091l7.999-8.875a1.179 1.179 0 00-.091-1.671 1.194 1.194 0 00-1.678.09l-7.999 8.876a1.179 1.179 0 00.091 1.671z"
                ></path>
                <path
                    fill="#FF9283"
                    d="M236.443 133.159a4.398 4.398 0 00-.603-1.239c-.648-.935-1.555-1.657-2.314-2.503-.835-.933-1.487-2.015-2.044-3.134-.274-.548-.543-1.128-1.027-1.503-.485-.375-1.264-.455-1.672.003-.307.345-.305.866-.207 1.318a4.04 4.04 0 001.48 2.324l-7.89-5.457c-.302-.208-.61-.421-.963-.529-.352-.109-.76-.097-1.059.117-.423.304-.494.944-.267 1.413.229.468.688.785 1.165.996-.535-.337-1.346-.084-1.59.497-.245.581.14 1.335.755 1.48-.489.027-.828.563-.802 1.052.025.488.313.922.621 1.302-.349-.235-.872-.049-1.081.316-.208.365-.15.835.047 1.207.197.372.513.664.828.943 2.931 2.594 6.283 4.664 9.617 6.72.303.186.608.375.941.499.431.161.895.212 1.354.243.74.052 1.511.054 2.193-.24.722-.313 1.273-.933 1.646-1.623.459-.846.787-1.881.963-2.823.086-.46.041-.93-.091-1.379zM171.507 156.471c.422.191.813.463 1.145.769.838.77 1.426 1.767 2.161 2.635.809.955 1.792 1.75 2.825 2.458.505.346 1.043.693 1.348 1.225.304.532.274 1.312-.236 1.65-.387.255-.905.18-1.339.019a4.055 4.055 0 01-2.1-1.788l4.311 8.553c.165.327.333.662.391 1.025.057.362-.011.764-.266 1.029-.362.375-1.007.356-1.44.064-.433-.291-.683-.788-.826-1.289.26.574-.105 1.339-.716 1.499-.611.16-1.307-.325-1.364-.953-.096.481-.675.739-1.157.646-.481-.093-.872-.439-1.207-.796.185.378-.073.868-.466 1.023-.391.154-.851.03-1.192-.215-.341-.246-.587-.6-.82-.951-2.165-3.258-3.749-6.856-5.323-10.435-.143-.325-.287-.653-.364-.999-.1-.449-.085-.914-.052-1.371.052-.738.159-1.499.547-2.131.411-.667 1.106-1.125 1.842-1.396.906-.334 1.978-.513 2.939-.555.468-.02.929.09 1.357.283l.002.001z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M171.003 149.596l2.626 7.737-9.546 3.859-.859-2.817 3.538-11.25 4.241 2.471z"
                ></path>
                <path
                    fill="#101030"
                    d="M225.385 128.151a.335.335 0 01-.181-.054l-4.342-2.858a.326.326 0 01-.093-.453.33.33 0 01.456-.092l4.342 2.858a.328.328 0 01-.182.601v-.002zM224.698 130.27a.333.333 0 01-.181-.054l-4.343-2.858a.327.327 0 11.363-.546l4.343 2.858a.327.327 0 01-.182.601v-.001zM224.377 132.568a.33.33 0 01-.181-.054l-4.343-2.858a.326.326 0 01-.093-.453.33.33 0 01.456-.092l4.343 2.858a.326.326 0 01-.182.6v-.001zM177.494 172.973a.328.328 0 01-.298-.189l-2.122-4.547a.328.328 0 01.596-.275l2.122 4.546a.326.326 0 01-.298.465zM175.371 173.427a.327.327 0 01-.298-.19l-2.122-4.546a.328.328 0 01.596-.276l2.122 4.547a.326.326 0 01-.298.465zM173.017 173.427a.328.328 0 01-.298-.19l-2.122-4.546a.328.328 0 01.596-.276l2.122 4.547a.325.325 0 01-.16.434.32.32 0 01-.138.031z"
                ></path>
                <path
                    fill="#C6264C"
                    d="M324.026 196.47l-37.53-12.346a3.009 3.009 0 00-3.796 1.903l-1.105 3.333a2.99 2.99 0 001.911 3.78l37.529 12.346a3.008 3.008 0 003.796-1.903l1.106-3.333a2.99 2.99 0 00-1.911-3.78z"
                ></path>
                <path
                    fill="#fff"
                    d="M333.196 211.858l-62.29-20.486a3.71 3.71 0 01-2.373-4.688l.649-1.964a3.733 3.733 0 014.712-2.365l62.29 20.486a3.711 3.711 0 012.373 4.689l-.649 1.963a3.732 3.732 0 01-4.712 2.365zM315.852 172.386l-13.297-4.373c-3.04-1.001-4.692-4.265-3.691-7.293l2.512-7.602a1.883 1.883 0 012.375-1.192l20.745 6.823a1.871 1.871 0 011.196 2.363l-2.51 7.594c-1.003 3.033-4.286 4.681-7.33 3.679v.001z"
                ></path>
                <path
                    fill="#101030"
                    d="M323.791 154.774l-17.613-5.794a3.51 3.51 0 01-2.246-4.435l8.456-25.575a2.657 2.657 0 013.353-1.683l19.275 6.339a2.642 2.642 0 011.689 3.336l-8.455 25.575a3.532 3.532 0 01-4.458 2.238l-.001-.001zM304.75 148.522l20.744 6.824a1.874 1.874 0 011.196 2.366l-2.519 7.595c-1.005 3.028-4.289 4.674-7.329 3.674l-13.296-4.374c-3.041-1-4.694-4.271-3.69-7.298l2.52-7.595a1.882 1.882 0 012.375-1.191l-.001-.001zM373.745 15.539l-24.513-8.064-10.419 31.412 24.513 8.064 10.419-31.412z"
                ></path>
                <path
                    fill="#101030"
                    d="M374.011 18.754L347.063 9.89c-2.668-.877-4.118-3.742-3.239-6.4l.002-.006c.88-2.66 3.76-4.107 6.431-3.229l26.949 8.864c2.667.877 4.118 3.742 3.239 6.4l-.002.006c-.88 2.66-3.761 4.107-6.432 3.229z"
                ></path>
                <path
                    fill="#20204C"
                    d="M326.88 161.079l-26.948-8.863c-2.667-.878-4.118-3.742-3.239-6.4l.002-.006c.88-2.661 3.761-4.108 6.432-3.229l26.948 8.863c2.668.878 4.118 3.742 3.239 6.4l-.002.006c-.88 2.661-3.761 4.108-6.432 3.229z"
                ></path>
                <path
                    fill="#63AFFA"
                    d="M412.83 147.966l-18.693 58.466c-10.483 32.786-45.628 50.889-78.5 40.434l-11.351-3.61 13.255-41.457a44.212 44.212 0 007.224 4.198 10.093 10.093 0 00-.373 2.727c0 5.581 4.536 10.105 10.131 10.105 1.155 0 2.265-.193 3.298-.549 0 0 22.234-7.66 31.449-16.642 9.967-7.225 16.793-18.499 18.047-31.399.138-1.419.209-2.857.209-4.311 0-3.164-.334-6.252-.97-9.227a43.632 43.632 0 00-2.828-8.684 44.078 44.078 0 00-6.882-10.836c-8.111-9.41-20.133-15.369-33.551-15.369l-.181.001 17.825-55.753 11.351 3.609c12.84 4.084 23.435 11.922 30.952 21.912a62.482 62.482 0 0112.13 30.191c1.013 8.554.252 17.455-2.541 26.194h-.001z"
                ></path>
                <path
                    fill="#20204C"
                    d="M382.415 268.68l-35.734-41.154c-9.802-11.288-28.417-4.385-28.417 10.538v30.616h64.151z"
                ></path>
                <path
                    fill="#63AFFA"
                    d="M410.516 284.879H230.077c0-2.441.365-4.797 1.043-7.018a24.037 24.037 0 016.023-9.998 24.09 24.09 0 0117.06-7.047h132.189c10.877 0 20.072 7.179 23.084 17.045a24.026 24.026 0 011.042 7.018h-.002z"
                ></path>
                <path
                    fill="#fff"
                    d="M331.605 241.098c3.331 0 6.032-2.693 6.032-6.016 0-3.322-2.701-6.016-6.032-6.016s-6.031 2.694-6.031 6.016c0 3.323 2.7 6.016 6.031 6.016z"
                ></path>
                <path
                    fill="#20204C"
                    d="M330.446 138.246l-19.627-6.454c-3.277-1.078-5.058-4.596-3.978-7.861l30.572-92.473c.813-2.459 3.475-3.795 5.943-2.983l22.572 7.423a4.68 4.68 0 012.992 5.913l-30.571 92.47c-1.081 3.269-4.62 5.045-7.902 3.966l-.001-.001z"
                ></path>
                <path
                    fill="#336"
                    d="M313.961 118.394l-.374-.123a1.59 1.59 0 01-1.016-2.009l25.752-77.892c.208-.628.888-.97 1.519-.762l1.126.371c.63.207.971.884.765 1.511l-25.751 77.892a1.6 1.6 0 01-2.019 1.013l-.002-.001z"
                ></path>
                <path
                    fill="#20204C"
                    d="M333.682 212.018l-63.261-20.806a3.202 3.202 0 01-2.048-4.045l.24-.723a3.222 3.222 0 014.066-2.041l63.262 20.807a3.202 3.202 0 012.047 4.045l-.239.722a3.222 3.222 0 01-4.067 2.041z"
                ></path>
                <path
                    fill="#101030"
                    d="M357.698 100.107c7.427 0 13.447-6.005 13.447-13.413 0-7.407-6.02-13.413-13.447-13.413s-13.448 6.006-13.448 13.413c0 7.408 6.021 13.413 13.448 13.413z"
                ></path>
                <path
                    fill="#fff"
                    d="M357.698 92.71c3.331 0 6.031-2.693 6.031-6.016 0-3.322-2.7-6.016-6.031-6.016-3.331 0-6.032 2.694-6.032 6.016 0 3.323 2.701 6.016 6.032 6.016z"
                ></path>
                <path
                    fill="#92CEFF"
                    d="M409.942 102.729l-26.215 45.288a44.078 44.078 0 00-6.882-10.836l26.396-45.6a62.482 62.482 0 016.701 11.148zM387.316 170.238a44.49 44.49 0 00-.761-13.537l26.524-45.82a62.086 62.086 0 012.293 10.891l-28.055 48.466h-.001z"
                ></path>
                <path
                    fill="#101030"
                    d="M410.516 284.879H230.077c0-2.441.365-4.797 1.043-7.018h178.355a24.026 24.026 0 011.042 7.018h-.001z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_0_1842">
                    <path
                        fill="#fff"
                        d="M0 0H318.818V299H0z"
                        transform="translate(136)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MainImg;
