import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import SharedButton from "../sharedButton";
export default function GetStartedToday(): ReactElement {
    return (
        <section className={`${styles["mainSection"]} `}>
            <div className="max-w-[730px] mx-auto text-center py-20">
                <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px]">
                    Get Started Today
                </h2>
                <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                    Ready to take the first step towards advancing medical
                    research and improving healthcare outcomes? Join our
                    clinical trials today and find the perfect study tailored to
                    your unique health needs. Let us help you make a difference
                    by connecting you with the right clinical trial and
                    providing the support you need throughout the process.
                </p>
                <div className="lg:mt-10 mt-5">
                    <SharedButton />
                </div>
            </div>
        </section>
    );
}
