import React from "react";

function MainImg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 449 377"
        >
            <path
                fill="#DFEEFA"
                d="M429.861 235.231C490.289 125.24 399.932-5.217 264.604.161c-40.794 1.622-81.02 16.268-111.162 55.71-69.74 91.26-132.37 69.112-150.756 140.818-18.39 71.706 59.888 148.425 165.959 63.538 77.529-62.045 151.904 117.299 240.853 8.804 8.85-10.796 14.403-22.951 20.363-33.8z"
            ></path>
            <path
                fill="#B3D3F5"
                d="M332.936 194.196c6.81 12.069 5.449 22.03 3.197 28.16 5.521-6.299 11.103-12.023 16.397-17.086-13.67-22.111 2.419-32.59 2.419-32.59 6.382 11.809 5.13 21.503 2.896 27.632l.073-.068a321.31 321.31 0 0120.424-17.047c-10.228-25.243 8.165-32.701 8.165-32.701 4.372 13.98.281 23.754-3.394 29.112 6.503-4.798 11.437-8.054 13.72-9.519 15.529-16.611 32.673-10.205 32.673-10.205-12.487 17.179-24.786 14.781-30.266 12.428-1.787 1.128-7.091 4.56-14.378 9.943 18.591-1.52 27.309 11.046 27.309 11.046-20.551 7.094-29.221-2.372-32.209-7.351a316.88 316.88 0 00-19.947 16.673c-.741.672-1.493 1.367-2.249 2.07 18.93-6.191 30.574 5.155 30.574 5.155-20.335 11.76-30.753 1.845-33.833-2.083-5.554 5.321-11.421 11.381-17.188 18.062 18.244-5.824 29.686 4.924 29.686 4.924-18.924 11.409-29.425 3.478-33.225-.723-12.088 14.681-23.351 32.065-29.857 51.149a1.588 1.588 0 01-2.463.745 1.582 1.582 0 01-.545-1.769c6.702-19.657 18.313-37.509 30.73-52.526-14.987-22.399 1.289-33.432 1.289-33.432l.002.001zM275.7 126.347c13.791 14.244 15.536 28.552 14.659 37.874 5.328-10.751 10.948-20.728 16.413-29.682-26.912-25.66-8.52-46.068-8.52-46.068 13.105 14.04 14.904 27.937 14.052 37.251l.075-.121c7.675-11.891 15.291-22.408 21.985-31.1-23.3-31.26-.629-48.323-.629-48.323 11.12 17.785 9.03 32.832 5.908 41.603 7.229-9.029 12.855-15.347 15.472-18.212 15.388-28.707 41.38-26.092 41.38-26.092-10.983 28.383-28.83 29.553-37.25 28.294-2.054 2.218-8.127 8.914-16.224 19.042 25.104-8.904 41.708 5.332 41.708 5.332-25.782 17.347-41.192 7.391-47.127 1.581a454.831 454.831 0 00-21.463 30.407 386.927 386.927 0 00-2.351 3.692c23.873-15.503 44.072-4.028 44.072-4.028-23.786 23.738-41.772 13.798-47.452 9.478-5.728 9.408-11.621 19.954-17.15 31.325 23.061-14.743 42.763-4.025 42.763-4.025-21.967 22.736-39.345 15.578-46.119 11.143-11.341 24.773-20.56 52.992-22.595 81.828a2.27 2.27 0 01-3.128 1.933 2.267 2.267 0 01-1.396-2.253c2.098-29.703 11.626-58.697 23.3-84.056-28.834-25.577-10.386-46.823-10.386-46.823h.003z"
            ></path>
            <g clipPath="url(#clip0_0_1931)">
                <path
                    fill="#D4E8FC"
                    d="M65.346 332.466L13.47 289.38c-7.179-6.154-2.613-17.445 8.138-20.128l155.602-38.815L65.342 332.463l.004.003z"
                ></path>
                <path
                    fill="#BFCEF5"
                    d="M81.765 336.509l155.59-42.647c5.084-1.487 8.399-6.053 8.379-11.533l-.968-250.032c-.037-9.297-9.295-16.801-18.047-14.625L71.116 56.316c-5.34 1.326-8.888 5.993-8.867 11.667l.995 256.946c.037 9.518 9.691 14.168 18.521 11.583v-.003z"
                ></path>
                <path
                    fill="#1D5F7A"
                    d="M85.67 338.892l155.589-42.646c5.084-1.487 8.399-6.053 8.379-11.533L248.67 34.68c-.037-9.296-9.295-16.8-18.047-14.625L75.02 58.7c-5.34 1.326-8.888 5.993-8.867 11.667l.995 256.946c.037 9.518 9.691 14.168 18.521 11.583v-.004z"
                ></path>
                <path
                    fill="#DFEEFA"
                    d="M78.416 322.918l159.914-43.029-.942-242.51L77.465 75.803l.951 247.116z"
                ></path>
                <path
                    fill="#7789C4"
                    d="M124.151 40.432l8.979-2.86-.021-4.268c-.053-11.828 9.446-24.55 21.114-28.267 11.669-3.72 21.262 2.918 21.316 14.746l.02 4.268 8.979-2.86c8.215-2.62 14.97 2.054 15.007 10.38.04 8.33-6.651 17.285-14.866 19.903L124.293 70.72c-8.215 2.62-14.971-2.054-15.008-10.38-.04-8.33 6.652-17.285 14.866-19.904v-.003zm29.38-13.558l1.59-.507c2.885-.92 5.233-4.062 5.219-6.986-.013-2.925-2.384-4.563-5.269-3.647l-1.591.507c-2.885.92-5.232 4.063-5.218 6.987.013 2.924 2.384 4.563 5.269 3.646z"
                ></path>
                <path
                    fill="#BBD0F3"
                    d="M128.994 42.99l8.981-2.44-.02-4.268c-.054-11.828 9.449-24.1 21.121-27.273 11.671-3.172 21.265 3.912 21.319 15.74l.02 4.267 8.982-2.44c8.218-2.233 14.973 2.753 15.01 11.082.041 8.33-6.651 16.969-14.869 19.202l-60.4 16.411c-8.218 2.233-14.974-2.753-15.011-11.083-.04-8.33 6.652-16.969 14.87-19.201l-.003.003zm29.386-12.177l1.59-.433c2.885-.783 5.236-3.818 5.222-6.742-.013-2.925-2.384-4.674-5.269-3.892l-1.59.434c-2.886.782-5.236 3.817-5.223 6.741.014 2.925 2.385 4.674 5.27 3.892z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M138.472 151.96c-14.001-7.858-17.224-28.662-7.199-46.469 10.024-17.806 29.5-25.871 43.5-18.014 14 7.857 17.224 28.662 7.199 46.468-10.024 17.807-29.5 25.872-43.5 18.015z"
                ></path>
                <path
                    fill="#fff"
                    d="M142.831 134.548c.292-4.408 2.845-8.061 4.755-9.616.766-.621 1.563-1.078 2.347-1.685a49.46 49.46 0 011.315-.997l.04-.031c.346-.251.693-.487 1.026-.688l.124-.074.007.01.511-.332.003.003.535-.675c.05-.063.239-.356.255-.312.004-.091-.02-.208-.023-.279l-.151-2.454c0-.013.006-.023.006-.037a3.416 3.416 0 01-1.109-1.343c-.195-.406-.323-.866-.417-1.346-1.197.238-1.601-1.306-1.799-2.391-.162-.869-.454-3.065.807-3.367l-.088-.353c-.195-.691-.37-1.4-.41-2.152-.04-.755.06-1.561.383-2.3.108-.245.273-.507.491-.58a1.131 1.131 0 01-.151-.595c-.003-1.89 1.503-3.24 2.646-4.002.996-.665 2.045-1.182 3.097-1.48a8.569 8.569 0 013.134-.188c1.17.14 2.734.668 2.845 2.531.014.242-.03.463-.114.665.218-.04.397.127.518.312.363.554.507 1.299.514 2.062.003.762-.124 1.554-.276 2.336-.023.121-.043.255-.067.397 1.261-.373 1.103 1.947.995 2.887-.131 1.172-.437 2.904-1.627 3.307a7.842 7.842 0 01-.33 1.544 6.484 6.484 0 01-1.012 1.911c0 .01.01.017.01.033 0 .833 0 1.666-.003 2.498 0 .071-.017.202-.007.289.017-.054.219.134.273.171.188.128.376.255.568.383 0 0 0-.003.003-.007l.525.057v-.013l.131.007c.34.02.696.07 1.052.131h.044v.006c.451.081.905.182 1.355.282.807.182 1.621.209 2.411.413 1.974.514 4.701 2.757 5.253 6.937.259 1.961-.71 3.542-2.509 4.089l-12.576 3.536-12.586 3.193c-1.806.419-2.852-.628-2.714-2.693h-.01z"
                ></path>
                <path
                    fill="#D4E8FC"
                    d="M252.511 345.691c-24.949-1.014-44.813-10.673-44.369-21.574.444-10.902 21.029-18.918 45.978-17.904 24.948 1.014 44.813 10.673 44.369 21.574-.445 10.902-21.03 18.917-45.978 17.904z"
                ></path>
                <path
                    fill="#DA7500"
                    d="M224.256 172.387c-.16-14.579 18.389-26.603 41.431-26.856 23.041-.253 41.851 11.361 42.011 25.94.161 14.579-18.388 26.603-41.43 26.856-23.042.253-41.851-11.361-42.012-25.94z"
                ></path>
                <path
                    fill="#DA7500"
                    d="M265.594 200.655c-23.05-.209-41.61-14.142-41.456-31.121.154-16.979 18.965-30.574 42.015-30.365 23.05.209 41.61 14.142 41.456 31.121-.155 16.979-18.965 30.574-42.015 30.365z"
                ></path>
                <path
                    fill="#B3D3F5"
                    d="M223.763 318.654c-.306 33.44 83.168 34.199 83.474.759l1.358-149.112-83.474-.758-1.358 149.111z"
                ></path>
                <path
                    fill="#4C74B5"
                    d="M219.486 166.487l94.301.084.205-10.928-94.301-.084-.205 10.928z"
                ></path>
                <path
                    fill="#4C74B5"
                    d="M219.481 166.92c-.052-12.284 21.018-22.331 47.06-22.44 26.042-.109 47.196 9.761 47.247 22.046.052 12.284-21.018 22.331-47.06 22.44-26.043.109-47.196-9.762-47.247-22.046z"
                ></path>
                <path
                    fill="#5A89D6"
                    d="M219.7 155.281c-.052-12.284 21.017-22.331 47.057-22.44 26.041-.109 47.192 9.762 47.244 22.046.051 12.284-21.017 22.331-47.057 22.44-26.041.109-47.192-9.761-47.244-22.046z"
                ></path>
                <path
                    fill="#E3F0FA"
                    d="M281.361 206.538v19.027a104.88 104.88 0 01-19.331 2.417v-19.026a104.967 104.967 0 0019.331-2.418z"
                ></path>
                <path
                    fill="#fff"
                    d="M287.468 204.99c5.626-1.628 10.989-3.723 15.845-6.325v19.027c-4.853 2.605-10.219 4.697-15.845 6.325V204.99z"
                ></path>
                <path
                    fill="#8FABE3"
                    d="M281.361 236.447v19.026a104.967 104.967 0 01-19.331 2.418v-19.027a104.966 104.966 0 0019.331-2.417z"
                ></path>
                <path
                    fill="#98B6F2"
                    d="M287.468 234.902c5.626-1.628 10.989-3.723 15.845-6.325v19.026c-4.853 2.606-10.219 4.697-15.845 6.326v-19.027z"
                ></path>
                <path
                    fill="#6987C2"
                    d="M281.361 266.358v19.027a104.966 104.966 0 01-19.331 2.417v-19.027a104.88 104.88 0 0019.331-2.417z"
                ></path>
                <path
                    fill="#799CE0"
                    d="M287.468 264.814c5.626-1.629 10.989-3.724 15.845-6.326v19.027c-4.853 2.605-10.219 4.697-15.845 6.325v-19.026z"
                ></path>
                <path
                    fill="#445B8A"
                    d="M281.361 296.27v19.026a104.967 104.967 0 01-19.331 2.418v-19.027a104.966 104.966 0 0019.331-2.417z"
                ></path>
                <path
                    fill="#546C9C"
                    d="M287.468 294.722c5.626-1.629 10.989-3.724 15.845-6.326v19.027c-4.853 2.605-10.219 4.697-15.845 6.325v-19.026z"
                ></path>
                <path
                    fill="#24386B"
                    fillRule="evenodd"
                    d="M126.499 175.821s-4.217 8.733-13.781 11.073c-7.192 1.759-10.044 7.02-13.013 14.877-4.751 12.567-17.233 7.232-18.85 25.607-.993 11.264 20.676 25.695 39.043 15.545 18.37-10.15 40.782 13.544 37.87-39.363-.363-6.587-7.173-5.674-6.638-11.758.585-6.667-8.336-16.965-8.336-16.965l-16.295.987v-.003z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#DE5840"
                    d="M138.446 203.641c-4.681-4.257.434-7.937.434-7.937l9.607-2.501c3.742 1.809 8.679 9.723 13.333 16.193a7.631 7.631 0 005.837 1.517l15.67-5.922.407 2.88-14.715 11.57c-6.285 3.146-13.652-2.501-14.974-3.331 0 0-10.03-7.403-15.599-12.469z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M187.645 206.783c-.125 1.071-.918 1.901-1.762 1.847l-3.047-.994c-.847-.054-.242-.097-.118-1.168.125-1.071-.665-1.256.992-1.514l2.381.208c.31.027.616.094.895.225.517.241.753.554.655 1.396h.004z"
                ></path>
                <path
                    fill="#fff"
                    d="M179.215 206.182s-.616 3.388.353 7.091l-7.445 5.329c-2.983 2.135-6.682 3.122-10.219 2.498-4.91-.867-9.16-4.023-11.191-8.81l-.121-.292c-4.042-9.831-4.254-24.661-4.254-24.661s2.889.5 5.058 3.838c1.61 2.477 8.336 11.593 11.695 16.129 1.207 1.628 3.174 2.494 5.256 2.32l10.871-3.448-.003.006z"
                ></path>
                <path
                    fill="#D4E8FC"
                    d="M106.4 346.349c8.814-5.852 24.134-7.702 34.225-4.129 1.722.607 3.171 1.339 4.361 2.155 6.258-2.699 14.84-3.3 20.815-1.185 7.088 2.508 7.815 7.877 1.624 11.989-5.952 3.952-16.117 5.295-23.192 3.17a21.49 21.49 0 01-1.298.933c-8.813 5.852-24.134 7.702-34.225 4.13-10.088-3.572-11.123-11.211-2.31-17.059v-.004z"
                ></path>
                <path
                    fill="#24386B"
                    d="M127.312 347.535l.014 5.281c-.034.557-.501 3.518-.727 4.042-.645 1.511-1.752 2.189-2.831 2.354-1.432.218-4.375-2.075-3.423-5.308.831-2.821 2.515-7.35 2.515-7.35 2.045.759 2.993 1.105 4.452.981zM158.783 347.847l-7.498-8.055c-1.147-.513-3.269-.581-4.987-.151-.774.195-1.174.735-1.211 1.514l-.165 1.454c-.205 2.431-.487 3.247 1.023 3.922l8.975 4.022c1.852 1.286 7.004.188 3.867-2.706h-.004z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M151.005 340.689s4.288 4.811 3.75 5.217c-2.223 1.676-7.603-2.783-7.603-2.783s2.875-2.619 3.853-2.434z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M147.152 343.123c2.038-.155 3.874.772 4.045-2.213-.434-16.609 2.963-30.952 2.569-46.48-.444-17.432-1.728-17.624-2.448-44.342l-16.14 3.935c3.436 18.969 5.262 43.14 5.262 43.14-3.857 18.989 3.06 23.321 6.712 45.963v-.003z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M122.971 348.045c1.295 2.844 3.739 3.472 4.183.497 1.086-16.67 6.201-30.697 7.227-46.283 1.15-17.495-.105-17.808 1.62-44.627l-16.342 2.458c1.675 19.308 1.278 43.68 1.278 43.68-5.552 18.661.484 21.27 2.034 44.275z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M149.943 229.732s11.14-3.213 9.207 58.167c0 0-11.591 5.46-22.362 8.884-10.152 3.23-16.682-8.232-24.9-4.918 0 0 5.125-12.023 4.472-17.469-.655-5.446-3.894-37.301 5.192-38.89 9.086-1.591 28.388-5.774 28.388-5.774h.003z"
                ></path>
                <path
                    fill="#2961DB"
                    d="M111.888 291.878s.74-12.822.084-18.268c-.656-5.446 1.012-41.303 10.101-42.895 2.075-.362 4.16 3.939 6.948 3.388-.66 5.375-1.971 14.195-4.537 28.649-3.423 19.269 2.825 29.411 8.985 34.609-8.332.245-14.849.893-21.581-5.486v.003z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M143.315 186.864a8.363 8.363 0 00-2.488-.021c-3.854.538-9.241-.473-9.631-.171l-7.125 4.17c-13.713 5.987-5.72 10.126-9.856 17.063 0 0 8.756 11.976 9.257 17.358.434 4.653-3.309 12.392-4.011 13.987-.912 2.068 6.13 13.43 24.046 9.139 14.109-3.378 12.129-9.025 11.342-16.717-.494-4.838-4.284-17.445-4.456-21.306-.171-3.861 2.603-7.115.155-13.051-2.451-5.936-4.217-9.975-4.217-9.975l-3.019-.476h.003z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M134.364 190.208l.434-.474c1.133.212 2.135 0 2.35-1.138l1.207-6.402a2.101 2.101 0 00-1.671-2.451l-.336-.064c-1.133-.212-2.902.171-3.114 1.306l-1.564 6.772c-.215 1.135 1.561 2.236 2.694 2.447v.004z"
                ></path>
                <path
                    fill="#BA4141"
                    d="M137.679 185.618s-2.754-.02-4.469-2.713c0 0 1.685 4.026 4.183 4.446l.283-1.73.003-.003z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M132.178 172.776c1.032-1.77 2.576-2.176 3.992-1.296 1.408-.695 3.086.064 4.3 2.202 1.692 2.975 2.085 7.847.579 10.429-.659 1.128-1.688 1.43-2.657 1.517a6.963 6.963 0 01-2.69-.305c-3.11-.971-3.171-.504-4.301-2.485-1.691-2.975-.733-7.48.774-10.059l.003-.003z"
                ></path>
                <path
                    fill="#24386B"
                    d="M134.159 173.749c-1.218 3.062-1.581 10.002-1.772 10.926 0 0-7.466.57-6.403-6.588 1.322-8.907 7.388-5.143 7.388-5.143l.79.802-.003.003z"
                ></path>
                <path
                    fill="#24386B"
                    d="M131.976 175.029c3.477-1.521 6.349-2.727 11.524 1.598.424-.769-.535-3.277-1.621-4.066-2.27-1.649-3.416-2.538-5.918-3.022l-.609-.05c-3.675-.299-5.676 1.178-6.574 4.764-.08.316-.753 1.763-.783 2.028l1.194-.433c-.232 2.112.679-1.054 2.784-.823l.003.004z"
                ></path>
                <path
                    fill="#fff"
                    d="M138.984 183.53c.706-.229 1.362-1.3 1.463-2.394l-2.563.822c-.1 1.095.391 1.797 1.1 1.572z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M130.791 182.654c-.71-.651-.723-1.79-.029-2.544.694-.753 1.831-.836 2.541-.185s.722 1.79.029 2.544c-.694.753-1.832.836-2.541.185z"
                ></path>
                <path
                    fill="#CED6A5"
                    d="M119.346 239.418c.703-1.595 4.446-9.334 4.012-13.987-.283-3.049-2.024-8.414-3.48-12.5 4.912 8.148 5.171 11.2 10.582 11.6-7.882 14.068-1.399 18.889-1.577 23.787-13.643.88-10.344-7.07-9.537-8.9z"
                ></path>
                <path
                    fill="#fff"
                    d="M137.595 186.112l1.9.721s.938 2.908-.568 4.298l-2.781-1.544s.878-1.004 1.244-2.24l.202-1.235h.003z"
                ></path>
                <path
                    fill="#fff"
                    d="M136.149 189.587l-1.657 2.86s-5.115-1.957-5.246-5.12l2.787-1.158s1.904 3.844 4.113 3.418h.003z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M127.154 348.542s-.827 5.751-3.214 5.472c-2.388-.282-.969-5.969-.969-5.969l4.183.497z"
                ></path>
                <path
                    fill="#fff"
                    d="M125.503 189.325c.626-.41 1.423-.192 1.638.44.925 2.736 2.993 9.165 2.996 12.023.007 3.679 2.545 32.721 2.545 32.721s-1.765 46.568-.269 53.273c0 0-15.643 4.405-23.266.259 0 0 .095-18.513.111-22.197.021-3.683 3.773-28.796 4.674-32.822.902-4.026-2.542-30.492-2.542-30.492s-1.019-4.711 8.135-9.704c3.076-1.678 4.926-2.81 5.978-3.495v-.006zM142.155 186.407c-.313-.071-.575.265-.42.537.924 1.615 3.06 5.688 3.746 10.079.887 5.668 1.637 20.434 1.637 20.434s3.955 19.99 4.479 26.319c.525 6.329.575 38.355.575 38.355 3.605-.275 8.972-2.289 10.841-5.539 0 0-2.807-20.686-3.857-25.144-1.049-4.456-3.261-21.028-3.261-21.028s-2.707-14.78-2.495-18.654c.212-3.875-1.453-15.978-1.453-15.978l-5.521-8.428-4.274-.956.003.003z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M114.218 203.671s-3.837 22.075 2.727 30.808 29.945-2.599 29.945-2.599l-.74-3.149-20.109-.51-1.883-24.909-9.936.359h-.004z"
                ></path>
                <path
                    stroke="#B4C5D6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M154.503 215.714l-.468-2.881"
                ></path>
                <path
                    fill="#fff"
                    d="M122.971 278.311l-9.358-11.312 87.788-100.542 9.358 11.311-87.788 100.543z"
                ></path>
                <path
                    fill="#9A1823"
                    d="M200.362 185.752l-6.184-7.477 6.924-7.93 6.184 7.477-6.924 7.93z"
                ></path>
                <path
                    fill="#A6202B"
                    d="M191.028 196.439l-6.184-7.474 6.927-7.93 6.18 7.474-6.923 7.93z"
                ></path>
                <path
                    fill="#DB545F"
                    d="M181.696 207.129l-6.184-7.477 6.924-7.93 6.184 7.477-6.924 7.93z"
                ></path>
                <path
                    fill="#F0626E"
                    d="M172.362 217.816l-6.184-7.474 6.927-7.93 6.184 7.474-6.927 7.93z"
                ></path>
                <path
                    fill="#2C67C9"
                    d="M163.03 228.506l-6.184-7.477 6.924-7.93 6.184 7.477-6.924 7.93z"
                ></path>
                <path
                    fill="#DFEEFA"
                    fillRule="evenodd"
                    d="M121.687 267.876c-1.376-1.703-1.211-4.54.359-6.302l2.909-3.267c1.574-1.766 3.985-1.82 5.357-.118 1.375 1.703 1.21 4.54-.36 6.302l-2.909 3.267c-1.573 1.766-3.984 1.82-5.356.118z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#DE5840"
                    d="M151.278 229.859c.064.964-.646 1.793-1.581 1.847l-3.245.188c-.931.053-1.748-.689-1.812-1.649-.064-.963.646-1.793 1.58-1.846l3.245-.188c.932-.054 1.749.688 1.813 1.648z"
                ></path>
                <path
                    fill="#fff"
                    d="M142.044 228.362l.178 7.551s-11.295 5.452-19.416 5.133c-8.12-.319-11.897-7.148-12.082-15.078-.185-7.927.639-24.006.639-24.006l10.008-5.687 4.468 7.453 1.177 21.595s.76 3.814 15.031 3.039h-.003z"
                ></path>
                <path
                    stroke="#B4C5D6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M125.066 211.202s-.565 10.589 1.17 13.097"
                ></path>
                <path
                    fill="#CEE1F5"
                    d="M185.21 344.835c.007.319.017.628.024.917l-.074 5.486c-.017.359-.034.718-.027 1.081.232 11.885 13.82 21.35 30.808 21.84.565.017 1.137.014 1.709.014-4.718 1.42-10.146 2.279-15.933 2.394-18.407.359-33.481-6.984-33.663-16.398-.128-6.477 6.823-12.245 17.16-15.334h-.004z"
                ></path>
                <path
                    fill="#E0EDF7"
                    d="M174.09 297.827c-.111-5.61 8.46-10.995 13.316-15.031 1.695-1.45 46.741-3.586 49.535-.967 5.01 3.844 13.783 8.709 13.891 14.32 0 0-4.493 57.063-4.553 57.711-1.19 12.53-14.197 22.78-32.251 23.133-.784.017-1.554.003-2.324-.017-16.984-.49-29.493-9.408-30.808-21.84-.037-.359-6.789-56.52-6.806-57.309z"
                ></path>
                <path
                    fill="#F0626E"
                    d="M186.152 340.108c-.081-4.197 51.623-6.299 51.704-2.102 0 0-1.14 14.064-1.187 14.551-.888 9.374-10.62 17.043-24.127 17.308-.585.01-1.164.003-1.739-.014-12.707-.366-22.062-7.037-23.047-16.337-.031-.269-1.594-12.812-1.604-13.403v-.003z"
                ></path>
                <path
                    fill="#DB545F"
                    d="M186.035 339.013c-.139-7.075 11.35-13.036 25.66-13.315 14.31-.28 26.023 5.229 26.162 12.303.138 7.075-11.35 13.036-25.66 13.316-14.311.279-26.024-5.229-26.162-12.304z"
                ></path>
                <path
                    fill="#A6202B"
                    d="M173.966 296.463c-.205-10.497 16.842-19.342 38.076-19.757 21.234-.414 38.614 7.759 38.82 18.256.205 10.497-16.842 19.342-38.076 19.757-21.234.414-38.614-7.759-38.82-18.256z"
                ></path>
                <path
                    fill="#A6202B"
                    d="M173.676 285.535l76.683-2.727.462 12.944-76.684 2.728-.461-12.945z"
                ></path>
                <path
                    fill="#9A1823"
                    d="M173.528 284.125c-.205-10.497 16.842-19.342 38.076-19.757 21.234-.414 38.614 7.759 38.82 18.256.205 10.497-16.842 19.342-38.076 19.757-21.234.414-38.614-7.759-38.82-18.256z"
                ></path>
                <path
                    stroke="#B4C5D6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M131.653 268.446l-8.682 9.865-9.358-11.312 19.086-21.544M157.896 238.31l-6.238 7.145"
                ></path>
                <path
                    fill="#004661"
                    d="M222.907 131.118c-2.078.038-4.175.094-6.272.132l-.11-5.826c-.019-1.267-1.049-2.478-2.336-2.421-1.251.057-2.373 1.059-2.354 2.421l.11 5.921-5.867.113c-2.759.057-2.778 4.483 0 4.426 1.986-.037 3.973-.075 5.959-.132l.11 5.826c.019 1.267 1.067 2.478 2.355 2.421 1.25-.057 2.372-1.059 2.354-2.421l-.11-5.921c2.06-.037 4.12-.094 6.18-.132 2.759-.057 2.777-4.483 0-4.426l-.019.019zM266.905 82.252c-1.089.02-2.187.05-3.285.07l-.058-3.052c-.01-.664-.549-1.298-1.224-1.268-.655.03-1.243.555-1.233 1.268l.058 3.101-3.073.06c-1.446.03-1.455 2.348 0 2.318 1.04-.02 2.081-.04 3.121-.07l.058 3.052c.009.664.559 1.298 1.233 1.269.655-.03 1.243-.555 1.233-1.269l-.058-3.1c1.08-.02 2.159-.05 3.238-.07 1.445-.03 1.454-2.348 0-2.319l-.01.01zM311.609 116.277c-2.376.043-4.772.108-7.168.151l-.126-6.658c-.021-1.448-1.199-2.832-2.67-2.767-1.429.065-2.712 1.211-2.691 2.767l.126 6.767-6.705.129c-3.153.065-3.174 5.124 0 5.059 2.27-.043 4.54-.087 6.811-.152l.126 6.659c.021 1.448 1.219 2.832 2.69 2.767 1.43-.065 2.712-1.211 2.691-2.767l-.126-6.767c2.354-.043 4.708-.108 7.063-.151 3.153-.065 3.174-5.123 0-5.059l-.021.022z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_0_1931">
                    <path
                        fill="#fff"
                        d="M0 0H304.002V373.003H0z"
                        transform="matrix(-1 0 0 1 314.002 4)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MainImg;
