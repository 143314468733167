import React from "react";

function Depression() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 56 56"
        >
            <path
                stroke="#5DB0E2"
                strokeDasharray="10 10"
                strokeLinecap="square"
                d="M0.5 0.5H54.5V54.5H0.5z"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M32.898 16.928c-1.486-1.076-3.388-1.869-5.579-1.843a.68.68 0 01-.016-1.358c2.54-.03 4.72.89 6.392 2.101 1.663 1.204 2.86 2.723 3.429 3.959.42.913 1.289 3.26 1.39 5.411.051 1.123.62 2.567 1.2 3.905.07.164.142.328.213.488.203.464.396.903.535 1.268.094.245.177.489.223.705.023.11.041.232.041.357 0 .12-.017.293-.108.465-.178.339-.479.52-.737.619-.257.098-.536.14-.781.158a6.86 6.86 0 01-.586.015v4.314a2.038 2.038 0 01-2.038 2.037h-3.714v3.065a.68.68 0 01-1.359 0v-4.423h5.073a.68.68 0 00.68-.68v-5.688h.679c.14 0 .275.005.41.01.246.008.49.016.75-.004.145-.012.257-.03.339-.053a4.642 4.642 0 00-.14-.412c-.127-.331-.3-.725-.498-1.178l-.23-.523c-.561-1.297-1.244-2.969-1.31-4.381-.089-1.905-.88-4.07-1.266-4.907-.462-1.002-1.497-2.344-2.992-3.427zm-14.932 3.13a.68.68 0 01.376.884c-.824 2.046-.84 4.298-.506 6.266.335 1.974 1.01 3.584 1.483 4.316 1.308 2.021 1.943 4.203 2.104 5.081l.011.06v5.93a.68.68 0 01-1.358 0V36.79c-.154-.777-.734-2.73-1.897-4.53-.595-.918-1.324-2.711-1.683-4.826-.36-2.122-.361-4.647.586-7a.68.68 0 01.884-.377z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M27.82 16.783a.68.68 0 01.68.68v6.112a.68.68 0 11-1.359 0v-6.113a.68.68 0 01.68-.679zM22.726 13.726a.68.68 0 01.68.68v6.112a.68.68 0 11-1.36 0v-6.113a.68.68 0 01.68-.679z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M30.105 22.13a.68.68 0 010 .96l-1.92 1.921a.68.68 0 01-.961 0l-1.922-1.92a.68.68 0 11.961-.962l1.441 1.441 1.44-1.44a.68.68 0 01.962 0zM25.011 19.073a.68.68 0 010 .96l-1.921 1.921a.68.68 0 01-.96 0l-1.922-1.92a.68.68 0 11.96-.961l1.442 1.44 1.44-1.44a.68.68 0 01.961 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Depression;
