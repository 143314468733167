import React from "react";

function Vaccines() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 55 55"
        >
            <path
                stroke="#5DB0E2"
                strokeDasharray="10 10"
                strokeLinecap="square"
                d="M0.5 0.5H54.5V54.5H0.5z"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M47.392 14.039a.863.863 0 01-1.221 0L40.903 8.77a.863.863 0 111.221-1.221l5.268 5.268a.863.863 0 010 1.22zM27.76 26.992a.863.863 0 01-1.22 0l-2.304-2.303a.863.863 0 011.221-1.221l2.303 2.303a.863.863 0 010 1.22zM32.193 20.832a.863.863 0 01-1.22 0l-1.44-1.44a.863.863 0 111.22-1.22l1.44 1.44a.863.863 0 010 1.22zM9.956 44.957a.863.863 0 010-1.221l9.903-9.902a.863.863 0 011.22 1.22l-9.902 9.903a.863.863 0 01-1.22 0zM43.074 21.926a.863.863 0 01-1.22 0l-8.867-8.866a.863.863 0 011.221-1.221l8.866 8.866a.863.863 0 010 1.22z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M29.718 32.404a5.097 5.097 0 11-7.209-7.209l11.917-11.917 1.221 1.22L23.73 26.417a3.37 3.37 0 104.767 4.767l9.672-9.672 1.22 1.22-9.672 9.672z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M46.909 12.334l-4.33-4.33-7.323 7.324 4.33 4.33 7.323-7.324zm-2.442 0l-4.882 4.882-1.888-1.888 4.882-4.882 1.888 1.888zM22.967 33.833a1.335 1.335 0 11-1.888-1.888l-1.22-1.22a3.062 3.062 0 004.33 4.329l-1.222-1.22z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Vaccines;
