import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import SharedButton from "../sharedButton";
export default function AdvancedTechnology(): ReactElement {
    return (
        <section
            className={` lg:mt-28 mt-16 md:p-20 py-10 px-5 ${styles["mainSection"]} lg:mb-[200px] mb-[100px]`}
        >
            <div className=" max-w-[550px] ">
                <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px]  ">
                    Advanced Technology & Patient-Centric Care
                </h2>
                <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                    We use advanced tools to match you with the best trials for
                    your condition, particularly in specialized areas like
                    cancer. Our tools can also provide personalized education
                    and answers to your questions, making the process smoother
                    and more engaging
                </p>
                <div className="lg:mt-10 mt-5">
                    <SharedButton />
                </div>
            </div>
        </section>
    );
}
