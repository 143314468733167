import { ReactElement } from "react";
import styles from "./style.module.scss";

export default function SharedButton(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <button
            onClick={() => scrollToTop()}
            className={` text-white py-4 sm:px-8 px-4 lg:text-xl font-semibold  ${styles["animation"]} rounded-2xl`}
        >
            Sign Up for Clinical Trials
        </button>
    );
}
