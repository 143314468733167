import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

export default function MainBanner(): ReactElement {
    const router = useRouter();
    const { domain } = useDomainContext();
    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={`${styles["main-banner"]}`}>
                    <Form
                        classes={{
                            formClassName: styles["form"],
                            stepTitleClassName: styles["step-title"],
                            stepDescriptionClassName:
                                styles["step-description"],
                            stepClassName: styles["step-block"],
                            formButtonClassName: styles["form-button"],
                            formFieldClassName: styles["form-field"],
                            formStepInnerClassName: styles["steps-inner"],
                            fieldsClassName: {
                                radio: styles["radio-field"],
                                input: styles["input-field"],
                                select: styles["select-field"],
                            },
                            fieldsWrapperClassName: styles["fields-wrapper"],
                            tcpaClassName: styles["tcpa"],
                            labelAsTitleClassName:
                                styles["label-as-step-title"],
                            formProgressbarClassName:
                                styles["progress-background"],
                        }}
                        colors={{
                            progressBar: "#b0b0b0",
                        }}
                        showProgress={false}
                        goToListPage={true}
                        goToThankYouPage={false}
                    />
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
