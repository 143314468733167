import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

const ButtonArrow = ({
    className,
}: {
    className?: string | null;
}): ReactElement => {
    return (
        <span className={styles["button-arrow"]}>
            <span className={`${styles["inner"]} ${className ?? ""}`}></span>
        </span>
    );
};

export default ButtonArrow;
