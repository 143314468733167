import React from "react";

function ExpertCareComprehensiveHealth() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 464 324"
        >
            <path
                fill="#DFEEFA"
                d="M19.119 238.231C-41.309 128.24 49.049-2.217 184.376 3.161c40.794 1.622 81.02 16.268 111.162 55.71 69.741 91.26 132.369 69.112 150.756 140.818 18.389 71.706-59.888 148.425-165.959 63.538-77.529-62.045-151.904 117.299-240.853 8.804-8.85-10.796-14.404-22.951-20.363-33.8z"
            ></path>
            <path
                fill="#B3D3F5"
                d="M138.822 183.78c-6.811 12.069-5.449 22.03-3.198 28.16-5.521-6.298-11.102-12.023-16.396-17.085 13.669-22.112-2.419-32.59-2.419-32.59-6.382 11.809-5.13 21.502-2.897 27.631l-.072-.068a321.238 321.238 0 00-20.424-17.046c10.227-25.244-8.165-32.702-8.165-32.702-4.372 13.98-.281 23.754 3.394 29.112-6.503-4.798-11.438-8.054-13.72-9.518-15.53-16.612-32.673-10.206-32.673-10.206 12.487 17.179 24.785 14.781 30.266 12.428 1.786 1.129 7.09 4.56 14.377 9.944-18.59-1.52-27.308 11.045-27.308 11.045 20.551 7.094 29.22-2.372 32.209-7.35a317.408 317.408 0 0119.947 16.672c.74.673 1.492 1.367 2.248 2.07-18.93-6.191-30.573 5.156-30.573 5.156 20.335 11.76 30.753 1.844 33.833-2.084 5.553 5.322 11.42 11.382 17.188 18.062-18.245-5.823-29.687 4.924-29.687 4.924 18.924 11.41 29.425 3.478 33.226-.723 12.088 14.682 23.35 32.066 29.857 51.149a1.588 1.588 0 002.462.745 1.58 1.58 0 00.545-1.768c-6.702-19.657-18.312-37.509-30.73-52.526 14.987-22.399-1.288-33.432-1.288-33.432h-.002zM196.058 115.932c-13.791 14.244-15.536 28.551-14.66 37.874-5.327-10.751-10.948-20.728-16.412-29.682 26.911-25.66 8.519-46.068 8.519-46.068-13.105 14.04-14.904 27.937-14.051 37.25-.024-.04-.051-.08-.076-.12-7.675-11.892-15.291-22.409-21.984-31.1 23.3-31.26.629-48.323.629-48.323-11.12 17.785-9.031 32.831-5.909 41.603-7.228-9.03-12.854-15.347-15.471-18.212-15.388-28.707-41.38-26.092-41.38-26.092 10.983 28.382 28.829 29.553 37.249 28.294 2.055 2.218 8.127 8.914 16.225 19.041-25.105-8.903-41.708 5.332-41.708 5.332 25.782 17.348 41.191 7.392 47.127 1.582a454.85 454.85 0 0121.463 30.407c.777 1.202 1.562 2.44 2.35 3.691-23.873-15.502-44.072-4.027-44.072-4.027 23.787 23.737 41.773 13.798 47.453 9.478 5.728 9.408 11.62 19.954 17.149 31.325-23.06-14.743-42.762-4.025-42.762-4.025 21.966 22.736 39.344 15.578 46.119 11.142 11.34 24.774 20.559 52.993 22.595 81.829a2.27 2.27 0 003.128 1.933 2.268 2.268 0 001.395-2.253c-2.097-29.703-11.625-58.698-23.3-84.057 28.835-25.576 10.386-46.822 10.386-46.822h-.002z"
            ></path>
            <g clipPath="url(#clip0_0_2017)">
                <path
                    fill="#C9DFF5"
                    d="M402.283 237.679l-110.758-68.603L353.242 0 464 68.605l-61.717 169.074z"
                ></path>
                <path
                    fill="#E1F0FC"
                    d="M438.543 92.993l-82.61-51.168 2.082-5.707 82.612 51.169-2.084 5.706zM433.611 106.505l-82.61-51.171.697-1.91 82.61 51.172-.697 1.909zM429.409 118.013l-82.61-51.169.697-1.908 82.61 51.168-.697 1.909zM425.21 129.524l-82.613-51.169.697-1.908 82.611 51.168-.695 1.909zM421.008 141.035l-82.613-51.169.698-1.91 82.61 51.17-.695 1.909zM416.806 152.546l-82.613-51.171.698-1.909 82.61 51.169-.695 1.911z"
                ></path>
                <path
                    fill="#D5E5F5"
                    d="M412.604 164.054l-82.61-51.168.695-1.909 82.613 51.169-.698 1.908z"
                ></path>
                <path
                    fill="#D4E8FC"
                    d="M393.111 272.664l41.714-34.595c5.773-4.941 2.101-14.007-6.544-16.161L303.16 190.742l89.954 81.92-.003.002z"
                ></path>
                <path
                    fill="#BFCEF5"
                    d="M379.906 275.91l-125.111-34.242c-4.088-1.194-6.754-4.86-6.738-9.26l.779-200.757c.03-7.465 7.473-13.49 14.512-11.743l125.121 31.029c4.294 1.065 7.146 4.812 7.13 9.367l-.8 206.308c-.03 7.643-7.793 11.377-14.893 9.301v-.003z"
                ></path>
                <path
                    fill="#1D5F7A"
                    d="M376.766 277.824l-125.11-34.242c-4.088-1.194-6.754-4.86-6.738-9.26l.779-200.757c.029-7.464 7.473-13.49 14.512-11.743L385.33 52.851c4.293 1.065 7.146 4.812 7.13 9.367l-.801 206.308c-.029 7.643-7.792 11.376-14.893 9.301v-.003z"
                ></path>
                <path
                    fill="#DFEEFA"
                    d="M382.599 264.998l-128.585-34.55.754-194.716 128.599 30.851-.768 198.415z"
                ></path>
                <path
                    fill="#7789C4"
                    d="M345.823 38.183l-7.22-2.297.016-3.426c.044-9.497-7.595-19.712-16.977-22.696-9.383-2.987-17.097 2.343-17.141 11.84l-.016 3.426-7.219-2.297c-6.606-2.102-12.038 1.65-12.068 8.336-.032 6.688 5.348 13.878 11.954 15.98l48.557 15.453c6.606 2.102 12.038-1.65 12.068-8.336.032-6.688-5.349-13.878-11.954-15.98v-.003zm-23.624-10.886l-1.279-.407c-2.32-.738-4.208-3.262-4.197-5.61.011-2.348 1.917-3.663 4.237-2.927l1.279.407c2.32.739 4.207 3.262 4.197 5.61-.011 2.348-1.917 3.663-4.237 2.927z"
                ></path>
                <path
                    fill="#BBD0F3"
                    d="M341.932 40.237l-7.222-1.96.016-3.429c.043-9.497-7.598-19.35-16.984-21.898-9.385-2.547-17.099 3.141-17.142 12.638l-.017 3.43-7.222-1.96c-6.608-1.793-12.04 2.21-12.07 8.898-.032 6.688 5.348 13.625 11.957 15.417l48.567 13.177c6.609 1.793 12.041-2.21 12.071-8.898.032-6.689-5.349-13.625-11.957-15.418l.003.003zm-23.63-9.777l-1.279-.348c-2.32-.628-4.21-3.065-4.199-5.413.011-2.348 1.917-3.753 4.237-3.125l1.279.348c2.32.628 4.21 3.065 4.197 5.413-.011 2.348-1.918 3.753-4.238 3.125h.003z"
                ></path>
                <path
                    fill="#fff"
                    d="M364.918 182.019l-95.573-25.524c-1.968-.525-3.512-2.704-3.447-4.863.064-2.159 1.714-3.486 3.682-2.96l95.573 25.524c1.968.525 3.512 2.704 3.447 4.863-.064 2.159-1.714 3.486-3.682 2.96zM364.207 205.69l-95.573-25.523c-1.969-.526-3.512-2.704-3.448-4.863.065-2.162 1.715-3.486 3.683-2.96l95.573 25.523c1.968.526 3.512 2.704 3.447 4.863-.065 2.16-1.714 3.486-3.682 2.96z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M302.942 135.68c11.257-6.309 13.849-23.013 5.788-37.31-8.06-14.298-23.721-20.774-34.979-14.465-11.257 6.31-13.849 23.014-5.789 37.311 8.061 14.297 23.722 20.773 34.98 14.464z"
                ></path>
                <path
                    fill="#F0626E"
                    d="M361.337 150.204c11.258-6.309 13.85-23.014 5.789-37.311-8.06-14.297-23.721-20.773-34.979-14.464-11.257 6.309-13.849 23.013-5.789 37.31 8.061 14.298 23.722 20.773 34.979 14.465z"
                ></path>
                <path
                    fill="#fff"
                    d="M299.437 121.704c-.235-3.54-2.288-6.473-3.823-7.721-.617-.499-1.258-.866-1.888-1.353a38.17 38.17 0 00-1.057-.801l-.032-.024a11.77 11.77 0 00-.825-.553l-.1-.059v.008l-.417-.267-.002.003-.43-.542c-.041-.051-.192-.286-.206-.251-.002-.073.017-.167.019-.224l.122-1.97c0-.011-.005-.019-.005-.03.356-.256.662-.598.892-1.078.157-.326.259-.696.335-1.081.963.191 1.287-1.049 1.447-1.92.129-.698.365-2.461-.649-2.704l.07-.283c.157-.555.297-1.124.33-1.728.032-.606-.049-1.253-.308-1.846-.087-.197-.219-.407-.395-.467a.9.9 0 00.122-.477c.002-1.517-1.209-2.601-2.128-3.213-.801-.534-1.644-.949-2.491-1.189a6.897 6.897 0 00-2.52-.15c-.941.112-2.198.536-2.287 2.032-.011.194.024.372.092.534-.176-.033-.319.102-.417.25-.292.445-.408 1.044-.413 1.655-.003.612.1 1.249.221 1.877.019.097.035.204.054.318-1.014-.3-.887 1.563-.8 2.318.105.941.352 2.332 1.309 2.655.051.418.13.836.265 1.241.197.593.478 1.094.814 1.533 0 .009-.009.014-.009.027 0 .669 0 1.337.003 2.006 0 .057.014.162.006.232-.014-.043-.176.108-.219.137l-.457.308s0-.003-.003-.006l-.422.046v-.011l-.105.006a7.83 7.83 0 00-.847.105h-.035v.005a26.73 26.73 0 00-1.09.227c-.648.145-1.303.167-1.938.331-1.587.413-3.78 2.214-4.224 5.57-.208 1.574.571 2.844 2.017 3.283l10.113 2.839 10.121 2.564c1.452.337 2.293-.505 2.182-2.162h.008zM354.202 126.049h.008l-5.345-15.293c-.62-1.771-2.683-2.318-3.394-.903l-5.846 11.646.006.008c-.798 1.207-1.277 2.739-1.293 4.51-.046 5.06 3.767 10.155 8.358 11.176 4.426.987 8.077-2.065 8.225-6.963.044-1.423-.221-2.844-.716-4.184l-.003.003z"
                ></path>
                <path
                    fill="#D4E8FC"
                    d="M273.469 320.913l-112.492-31.605c-3.899-1.094-3.639-4.251.576-7.012l40.064-26.238c4.216-2.76 10.854-4.122 14.753-3.027l112.491 31.605c3.899 1.095 3.639 4.252-.576 7.012l-40.064 26.238c-4.215 2.76-10.853 4.122-14.752 3.027z"
                ></path>
                <path
                    fill="#2C4482"
                    d="M309.32 262.523c-.114 0-.23-.016-.346-.051l-92.95-27.481c-3.542-1.049-9.823.334-13.72 3.016l-5.446 3.75a1.216 1.216 0 01-1.689-.307 1.208 1.208 0 01.308-1.685l5.445-3.75c4.489-3.092 11.573-4.593 15.789-3.345l92.949 27.481a1.21 1.21 0 01-.343 2.372h.003zM309.32 226.324c-.114 0-.23-.016-.346-.051l-92.95-27.481c-3.542-1.049-9.823.334-13.72 3.017l-5.446 3.749a1.216 1.216 0 01-1.689-.307 1.208 1.208 0 01.308-1.685l5.445-3.75c4.489-3.092 11.573-4.593 15.789-3.345l92.949 27.481a1.21 1.21 0 01-.343 2.372h.003z"
                ></path>
                <path
                    fill="#BAD1F5"
                    d="M278.217 219.787l.056-9.465c.041-6.807 6.122.218 13.415-.248l.091-1.936c7.293-.466 13.755-6.365 13.715.442l-.392 66.734c-.041 6.807-6.038 12.757-13.331 13.223l-.705.046c-7.293.466-13.225-4.72-13.185-11.527l.338-57.269"
                ></path>
                <path
                    fill="#2F81EC"
                    d="M291.871 282.002h-.427c-4.429.017-8.03-3.402-8.006-7.593l.238-41.1c.024-4.192 3.666-7.632 8.095-7.648h.428c4.429-.016 8.03 3.402 8.006 7.594l-.238 41.1c-.024 4.192-3.667 7.631-8.096 7.647z"
                ></path>
                <path
                    fill="#fff"
                    d="M291.771 220.91c11.211-.285 20.197-4.535 20.07-9.493-.127-4.957-9.318-8.745-20.529-8.461-11.211.285-20.197 4.535-20.07 9.493.126 4.958 9.318 8.746 20.529 8.461z"
                ></path>
                <path
                    fill="#8DB4EB"
                    d="M279.45 211.327a.385.385 0 01-.282-.121.382.382 0 01.022-.539c2.355-2.168 7.092-3.577 12.37-3.68 4.973-.092 9.575 1.025 12.003 2.925a.38.38 0 11-.47.599c-2.299-1.799-6.714-2.853-11.516-2.764-5.019.097-9.678 1.461-11.865 3.478a.388.388 0 01-.26.102h-.002z"
                ></path>
                <path
                    fill="#BAD1F5"
                    d="M248.522 277.153l-.706.046c-7.292.466-13.224-4.721-13.184-11.528l.392-66.733c.041-6.807 6.122.218 13.414-.248l.092-1.936c7.293-.466 13.755-6.365 13.715.442l-.393 66.734c-.04 6.807-6.037 12.756-13.33 13.223z"
                ></path>
                <path
                    fill="#F0626E"
                    d="M248.625 270.618h-.427c-4.429.016-8.031-3.402-8.007-7.594l.238-41.1c.025-4.192 3.667-7.631 8.096-7.647h.427c4.429-.017 8.031 3.402 8.006 7.594l-.238 41.099c-.024 4.192-3.666 7.632-8.095 7.648z"
                ></path>
                <path
                    fill="#fff"
                    d="M248.737 206.48c11.212-.285 20.198-4.535 20.071-9.493-.127-4.957-9.318-8.745-20.53-8.46-11.211.284-20.197 4.534-20.07 9.492.127 4.958 9.318 8.746 20.529 8.461z"
                ></path>
                <path
                    fill="#BAD1F5"
                    d="M204.516 267.41l-.706.046c-7.292.466-13.225-4.72-13.184-11.527l.392-66.734c.041-6.807 6.122.218 13.414-.248l.092-1.936c7.293-.466 13.755-6.364 13.714.443l-.392 66.733c-.04 6.807-6.038 12.757-13.33 13.223z"
                ></path>
                <path
                    fill="#fff"
                    d="M204.619 260.873h-.428c-4.429.016-8.03-3.402-8.006-7.594l.238-41.1c.024-4.192 3.667-7.632 8.096-7.648h.427c4.429-.016 8.031 3.402 8.006 7.594l-.238 41.1c-.024 4.192-3.666 7.632-8.095 7.648zM204.944 193.638c11.212-.285 20.198-4.535 20.071-9.493-.127-4.957-9.318-8.745-20.529-8.461-11.212.285-20.198 4.535-20.071 9.493.127 4.958 9.318 8.746 20.529 8.461z"
                ></path>
                <path
                    fill="#8DB4EB"
                    d="M290.568 221.914c-4.342 0-8.417-.596-11.838-1.739a.38.38 0 11.244-.723c3.577 1.195 7.906 1.777 12.505 1.691 5.235-.103 10.191-1.081 13.958-2.755a.382.382 0 11.314.695c-3.859 1.717-8.921 2.717-14.253 2.823-.308.005-.619.008-.924.008h-.006zM247.83 207.259c-4.345 0-8.42-.595-11.838-1.738a.38.38 0 11.244-.723c3.577 1.194 7.9 1.777 12.505 1.69 5.235-.099 10.191-1.078 13.958-2.755a.381.381 0 11.313.696c-3.858 1.717-8.92 2.717-14.252 2.822-.308.006-.619.008-.925.008h-.005zM203.267 194.414c-4.343 0-8.418-.596-11.838-1.739a.38.38 0 11.243-.722c3.526 1.178 7.971 1.776 12.506 1.69 5.235-.102 10.191-1.081 13.958-2.755a.38.38 0 11.313.695c-3.858 1.718-8.92 2.718-14.252 2.823-.308.005-.619.008-.925.008h-.005z"
                ></path>
                <path
                    fill="#2C4482"
                    d="M276.562 270.632c-1.26 0-2.431-.14-3.437-.44l-112.494-33.255c-2.777-.822-3.439-2.491-3.588-3.413-.319-1.997 1.071-4.227 3.818-6.116l29.305-20.178a1.217 1.217 0 011.69.307 1.21 1.21 0 01-.308 1.685L162.243 229.4c-2.261 1.555-2.92 2.989-2.799 3.744.136.839 1.236 1.281 1.88 1.472l112.491 33.258c3.542 1.046 9.823-.334 13.719-3.017l40.064-27.586c2.261-1.555 2.92-2.989 2.799-3.744-.135-.838-1.236-1.28-1.879-1.472l-19.544-5.777a1.21 1.21 0 11.689-2.321l19.544 5.777c2.777.822 3.439 2.491 3.588 3.413.319 1.998-1.071 4.227-3.818 6.117l-40.064 27.586c-3.417 2.353-8.336 3.784-12.351 3.784v-.002z"
                ></path>
                <path
                    fill="#2C4482"
                    d="M276.562 306.831c-1.26 0-2.431-.141-3.437-.44l-112.494-33.258c-2.777-.822-3.439-2.491-3.588-3.412-.319-1.998 1.071-4.227 3.818-6.117l29.338-20.202a1.218 1.218 0 011.69.307 1.21 1.21 0 01-.309 1.685l-29.337 20.202c-2.261 1.555-2.92 2.99-2.799 3.744.136.839 1.236 1.281 1.88 1.472l112.491 33.258c3.542 1.049 9.823-.334 13.719-3.016l40.064-27.589c2.261-1.555 2.92-2.99 2.799-3.744-.135-.839-1.236-1.281-1.879-1.472l-19.544-5.777a1.212 1.212 0 01-.82-1.505 1.216 1.216 0 011.509-.816l19.544 5.777c2.777.822 3.439 2.49 3.588 3.412.319 1.998-1.071 4.227-3.818 6.117l-40.064 27.589c-3.417 2.353-8.336 3.785-12.351 3.785z"
                ></path>
                <path
                    fill="#2C4482"
                    d="M158.214 290.583a1.182 1.182 0 01-1.184-1.181v-50.893c0-.653.53-1.181 1.184-1.181.654 0 1.184.528 1.184 1.181v50.893c0 .653-.53 1.181-1.184 1.181zM279.087 324a1.182 1.182 0 01-1.184-1.181v-50.893c0-.653.53-1.181 1.184-1.181.655 0 1.185.528 1.185 1.181v50.893c0 .653-.53 1.181-1.185 1.181zM331.627 290.616a1.183 1.183 0 01-1.184-1.181v-50.894c0-.652.53-1.181 1.184-1.181.654 0 1.184.529 1.184 1.181v50.894c0 .652-.53 1.181-1.184 1.181z"
                ></path>
                <path
                    fill="#8DB4EB"
                    d="M236.522 196.924a.382.382 0 01-.259-.661c2.355-2.167 7.092-3.577 12.37-3.679 4.964-.092 9.572 1.024 12.003 2.925a.38.38 0 11-.471.598c-2.298-1.795-6.711-2.852-11.516-2.763-5.018.097-9.677 1.461-11.865 3.477a.382.382 0 01-.259.103h-.003zM192.773 184.448a.382.382 0 01-.26-.661c2.355-2.167 7.093-3.577 12.371-3.679 4.967-.092 9.574 1.024 12.002 2.924a.38.38 0 11-.47.599c-2.298-1.798-6.714-2.852-11.516-2.763-5.019.097-9.677 1.461-11.865 3.477a.384.384 0 01-.259.103h-.003z"
                ></path>
                <path
                    fill="#24386B"
                    fillRule="evenodd"
                    d="M433.576 192.061c-.238-1.084.108-2.197.759-3.095 5.303-7.29-5.837-12.401-9.093-14.768-3.396-2.469.565-8.354-6.679-10.748-7.241-2.391-9.58 1.397-9.923 1.284-.508-.165.906 6.979.906 6.979s3.612 1.148 3.566 8.359c-.046 7.209-1.949 10.962 2.528 16.615 4.478 5.653 22.175-1.71 22.175-1.71-2.944-.024-3.939-1.55-4.239-2.916z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#D4E8FC"
                    d="M426.702 306.383c-7.122-4.157-19.5-4.78-27.647-1.391-1.39.58-2.561 1.237-3.524 1.952-5.059-1.798-11.989-1.801-16.813.207-5.724 2.383-6.308 6.683-1.303 9.603 4.81 2.809 13.022 3.313 18.736 1.234.33.23.676.453 1.049.669 7.122 4.16 19.5 4.78 27.647 1.391 8.147-3.391 8.977-9.508 1.855-13.668v.003z"
                ></path>
                <path
                    fill="#144566"
                    d="M406.242 316.765l5.81-7.088c.909-.504 2.623-.728 4.027-.517.63.094.97.498 1.024 1.124l.179 1.156c.238 1.941.495 2.578-.706 3.243l-7.127 3.955c-1.458 1.184-5.654.709-3.207-1.876v.003z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M412.307 310.381s-3.315 4.219-2.872 4.502c1.849 1.175 6.035-2.566 6.035-2.566s-2.382-2.163-3.163-1.936z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M416.736 311.16c-1.171 2.289-4.548 1.027-4.778-1.356-.157-13.371-3.177-23.828-3.334-36.326-.173-14.031.854-14.287.619-35.799l13.114 1.884c-2.19 15.503-2.92 35.056-2.92 35.056 3.683 14.942-.452 18.075-2.699 36.544l-.002-.003z"
                ></path>
                <path
                    fill="#1E56D0"
                    d="M423.171 224.971l-.73 20.73-1.809 27.848s5.613 16.646-.887 33.085c0 0-7.1 1.55-9.207.307 0 0-3.29-27.982-3.312-29.527-.022-1.544-2.05-49.5-2.05-49.5l17.992-2.943h.003z"
                ></path>
                <path
                    fill="#144566"
                    d="M384.491 311.427l6.079-6.861c.927-.469 2.647-.628 4.042-.364.625.119.949.537.982 1.162l.135 1.165c.165 1.949.395 2.593-.83 3.213l-7.274 3.682c-1.5 1.127-5.678.494-3.134-1.994v-.003z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M390.794 305.275s-3.474 4.09-3.039 4.389c1.801 1.245 6.13-2.337 6.13-2.337s-2.299-2.251-3.088-2.055l-.003.003z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M395.072 306.281c-1.258 2.243-4.464.798-4.602-1.594.351-13.365-2.269-23.93-1.947-36.425.36-14.029 1.398-14.247 1.979-35.751l13.033 2.377c-2.777 15.409-4.253 34.919-4.253 34.919 3.112 15.072-1.26 18.099-4.207 36.471l-.003.003z"
                ></path>
                <path
                    fill="#1E56D0"
                    d="M422.557 219.156s3.237 5.844-.032 14.323c-2.115 5.483-15.74 8.011-15.74 8.011l-3.391-.814-2.877 28.106s2.737 16.474-4.385 32.654c0 0-4.913 1.656-6.971.335 0 0-2.223-28.088-2.187-29.63.037-1.544 2.663-50.187 2.663-50.187l32.917-2.798h.003z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M355.981 170.618c-.824.836-.952 2.055-.278 2.704l3.112 1.798c.673.653.265.13 1.092-.706.825-.836 1.571-.321.563-1.849l-1.947-1.798a3.128 3.128 0 00-.857-.575c-.592-.258-1.036-.234-1.685.426z"
                ></path>
                <path
                    fill="#B3D3F5"
                    d="M396.024 196.153c5.81-.138 4.496-5.419 4.496-5.419l-4.434-8.777c-3.683-.933-13.358 2.005-20.485 3.599-1.928-.097-3.204-.863-4.459-2.243l-10.204-10.446-2.737 1.968 7.13 14.379c2.445 5.596 10.954 6.068 12.368 6.273 0 0 11.411.83 18.322.666h.003z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M445.148 232.441c1.004-.615 1.417-1.769.92-2.561l-2.591-2.491c-.497-.795-.224-.189-1.23.426-1.003.614-1.601-.062-.99 1.66l1.461 2.211c.189.288.419.55.694.763.511.391.95.474 1.739-.011l-.003.003z"
                ></path>
                <path
                    fill="#B3D3F5"
                    d="M420.026 183.989c-5.675-1.253-3.731 12.428-3.731 12.428 3.35 1.785 8.341 7.2 14.469 13.732 3.217 5.222 10.109 18.925 10.109 18.925l3.129-1.259-3.472-15.663c-1.314-4.769-1.503-5.157-4.294-10.381 0 0-9.461-16.293-16.21-17.784v.002z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M394.063 191.468s-.038-11.584 6.325-11.46c.887.016 2.366-.814 3.388-.774 5.31.216 4.559.251 8.425 1.698 1.458.545 2.409 4.723 2.188 6.29l2.39 4.68-22.713-.434h-.003z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M398.2 179.7c1.039-.151 2.404-.425 2.269.178-.595 2.65 2.917 13.134 9.42 1.076.192-.359.619-.968 1.012-.731l.338.133c1.111.434 2.284.695 3.471.8 12.282 1.089 8.626 7.349 6.019 13.164 0 0-5.083 9.869-5.156 14.239-.062 3.777 3.404 9.567 4.067 10.769.857 1.559-4.097 11.43-18.714 9.95-7.133-.722-10.938-3.393-12.165-6.305-.755-1.787-.046-3.256.016-5.634.103-3.931.298-19.782.2-22.893-.097-3.113-.495-.245 1.109-5.267 1.603-5.022 2.631-8.435 2.631-8.435l5.486-1.041-.003-.003z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M424.626 221.199c-.66-1.199-2.334-19.779-2.269-23.555.041-2.472-3.858-.726-2.939-4.157-1.858 3.814-3.218 7.688-4.756 10.589-1.915 3.615-2.334 7.804-1.469 11.799 1.482 6.855-2.46 11.891-7.468 13.606 10.986-.787 19.66-6.904 18.903-8.282h-.002z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M406.815 184.397l.36-.084a2.12 2.12 0 001.573-2.69l-1.871-6.926c-.354-1.197-1.763-1.774-2.98-1.493l-.359.083a2.121 2.121 0 00-1.574 2.691l.944 6.712c.354 1.197 2.687 1.987 3.907 1.707z"
                ></path>
                <path
                    fill="#BA4141"
                    d="M402.188 177.005s3.129.315 4.613-1.656c0 0-1.506 3.012-4.469 3.001l-.144-1.345z"
                ></path>
                <path
                    fill="#313363"
                    d="M399.452 169.834s-1.306 5.038 1.563 6.346c2.869 1.307 4.345-9.063 4.345-9.063l-5.908 2.717z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M408.351 167.642c-.655-1.35-1.793-1.693-2.923-1.07-1.014-.555-2.336-.03-3.407 1.537-1.49 2.181-2.131 5.814-1.176 7.782.416.86 1.173 1.114 1.898 1.203a5.428 5.428 0 002.055-.162c2.42-.647 2.433-.297 3.426-1.747 1.489-2.181 1.084-5.572.127-7.54v-.003z"
                ></path>
                <path
                    fill="#24386B"
                    d="M406.785 168.319c.703 2.321.487 7.518.565 8.214 0 0 4.751.154 4.456-5.222-.365-6.693-4.369-3.572-4.369-3.572l-.655.58h.003z"
                ></path>
                <path
                    fill="#24386B"
                    d="M408.343 169.33c-2.518-1.227-4.602-2.203-8.821.9-.265-.585.636-2.437 1.512-2.997 1.83-1.173 2.761-1.812 4.686-2.109l.462-.021c2.799-.132 4.205 1.024 4.629 3.731.038.237.444 1.337.449 1.536l-.871-.353c.028 1.585-.438-.806-2.046-.687z"
                ></path>
                <path
                    fill="#fff"
                    d="M402.445 175.509c-.519-.189-.935-1.006-.935-1.828l1.876.682c0 .822-.421 1.334-.941 1.146z"
                ></path>
                <path
                    fill="#DE5840"
                    d="M409.202 174.029a1.356 1.356 0 01-2.714-.006c0-.747.611-1.35 1.36-1.35.749 0 1.354.609 1.354 1.356z"
                ></path>
                <path
                    fill="#5DB0E2"
                    d="M377.761 184.453s2.745 11.314 2.117 13.918l12.649-2.197.184-15.436-14.952 3.715h.002zM420.056 182.278c.552.25 6.8 1.086 14.396 13.896l-8.469 10.433-12.441-13.123 6.511-11.206h.003z"
                ></path>
                <path
                    fill="#24386B"
                    d="M389.002 197.199a.41.41 0 01-.411-.41v-4.262a.41.41 0 01.821 0v4.262a.41.41 0 01-.41.41zM422.354 202.305a.41.41 0 01-.34-.181l-5.013-7.454a.408.408 0 01.113-.569.412.412 0 01.571.114l5.013 7.453a.409.409 0 01-.114.569.417.417 0 01-.23.07v-.002z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_0_2017">
                    <path
                        fill="#fff"
                        d="M0 0H307V324H0z"
                        transform="translate(157)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default ExpertCareComprehensiveHealth;
