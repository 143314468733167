import React, { ReactElement } from "react";
import MainImg from "./mainImg";
import SharedButton from "../sharedButton";

export default function PersonalizedMatching(): ReactElement {
    return (
        <section className=" flex flex-col lg:flex-row lg:gap-28 gap-16  lg:mt-28 mt-14 lg:mb-48 mb-20 ">
            <div className="max-w-[574px]  mx-auto lg:mx-0 order-1 lg:order-2 ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] ">
                    Personalized Matching & Efficient Processes
                </h2>
                <p className="mt-5   lg:text-lg text-base lg:leading-8  font-light">
                    We use efficient patient matching techniques and remove many
                    participation barriers, leading to faster trial completion
                    and quicker availability of new treatments. We also make it
                    simple to join our trials by offering participation from
                    home via telemedicine and remote monitoring. This is
                    especially helpful if you live far from research sites or
                    have mobility issues, ensuring you can contribute without
                    the need for frequent travel.
                </p>
                <div className="lg:mt-[50px] mt-7 text-center sm:text-left">
                    <SharedButton />
                </div>
            </div>
            <div className="w-[250px] h-[250px] md:h-[372px] md:w-[448px] md:min-w-[448px] relative mx-auto  order-1 lg:order-1 lg:mt-10">
                <MainImg />
            </div>
        </section>
    );
}
