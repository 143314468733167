import React from "react";

function CardiovascularDiseases() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 56 56"
        >
            <path
                stroke="#5DB0E2"
                strokeDasharray="10 10"
                strokeLinecap="square"
                d="M1.5 0.5H55.5V54.5H1.5z"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M15.06 18.958c1.134-2.26 3.235-4.143 6.545-4.143 3.193 0 5.263 1.33 6.504 3.052 1.21 1.679 1.593 3.668 1.581 5.006a.834.834 0 01-1.669-.014c.01-1.067-.308-2.687-1.266-4.016-.927-1.285-2.493-2.36-5.15-2.36-2.541 0-4.136 1.393-5.054 3.223-.941 1.875-1.12 4.13-.801 5.567.742 3.34 4.007 8.796 11.664 14.104.273.19.64.186.914-.014 1.99-1.45 4.55-3.434 6.8-5.843 2.257-2.416 4.15-5.206 4.88-8.26.462-1.936 1.099-5.134-1.986-7.458-1.867-1.407-3.574-1.482-4.91-1.15a6.678 6.678 0 00-2.778 1.483.834.834 0 01-1.141-1.218c.508-.476 1.777-1.452 3.516-1.884 1.78-.442 4.013-.3 6.317 1.436 4.001 3.014 3.063 7.26 2.605 9.178-.824 3.45-2.928 6.491-5.282 9.012-2.36 2.526-5.022 4.583-7.037 6.053a2.457 2.457 0 01-2.849.037c-7.875-5.459-11.48-11.234-12.342-15.114-.404-1.815-.173-4.463.938-6.677z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default CardiovascularDiseases;
