import React, { ReactElement } from "react";
import Vaccines from "./icons/vaccines";
import Cancers from "./icons/cancers";
import style from "./style.module.scss";
import CardiovascularDiseases from "./icons/cardiovascularDiseases";
import RareDiseases from "./icons/rareDiseases";
import Migraine from "./icons/migraine";
import Asthma from "./icons/asthma";
import Pediatrics from "./icons/pediatrics";
import Depression from "./icons/depression";
const list = [
    {
        title: "Vaccines:",
        description:
            "Participating in trials for vaccines against Ebola, HIV/AIDS, TB, and other emerging infectious diseases is crucial to prevent outbreaks and save countless lives worldwide.",
        icon: <Vaccines />,
    },
    {
        title: "Cancers:",
        description:
            "By joining clinical trials for cancers such as breast, lung, and leukemia, you contribute to pioneering treatments that can drastically improve survival rates and quality of life for patients.",
        icon: <Cancers />,
    },
    {
        title: "Cardiovascular Diseases:",
        description:
            "Participating in trials for vaccines against Ebola, HIV/AIDS, TB, and other emerging infectious diseases is crucial to prevent outbreaks and save countless lives worldwide.",
        icon: <CardiovascularDiseases />,
    },
    {
        title: "Rare Diseases:",
        description:
            "For rare diseases such as cystic fibrosis, ALS, and muscular dystrophy, clinical trials are often the best hope for new treatments and potential cures.",
        icon: <RareDiseases />,
    },
    {
        title: "Migraine:",
        description:
            "Migraine trials can lead to improved treatments for debilitating conditions such as chronic and cluster headaches.",
        icon: <Migraine />,
    },
    {
        title: "Asthma:",
        description:
            "Trials for allergic and non-allergic asthma are critical in developing treatments that can prevent severe attacks and improve daily living for patients.",
        icon: <Asthma />,
    },
    {
        title: "Pediatrics:",
        description:
            "Children with conditions like Type 1 diabetes, juvenile arthritis, and cerebral palsy rely on clinical trials for new and improved treatments.",
        icon: <Pediatrics />,
    },
    {
        title: "Depression:",
        description:
            "Participating in clinical trials is crucial for developing better treatments and improving mental health outcomes for conditions like bipolar disorder and postpartum depression.",
        icon: <Depression />,
    },
];

export default function MatchTrials(): ReactElement {
    return (
        <section className={`mb-24 lg:mb-44   `}>
            <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  max-w-[760px] mx-auto text-center mb-14 lg:mb-28">
                We Match You With Trials for the Following Categories &
                Conditions:
            </h2>
            <div className="flex flex-wrap gap-6 justify-center">
                {list?.map((item, index) => (
                    <div
                        key={index}
                        className={`  max-w-[386px]  py-12 px-10 ${style["oneBoxStyle"]}`}
                    >
                        <span
                            className={
                                "w-[55px] h-[55px] min-w-[55px] relative  block"
                            }
                        >
                            {item.icon}
                        </span>

                        <h3
                            className={
                                "font-bold lg:text-xl text-lg text-[#5DB0E2] mt-3"
                            }
                        >
                            {item.title}
                        </h3>
                        <div className={"text-base font-light mt-3"}>
                            {item.description}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
