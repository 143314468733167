import React, { ReactElement } from "react";
import Efficiency from "./icons/efficiency";
import Diversity from "./icons/diversity";
import Transparency from "./icons/transparency";
import Support from "./icons/support";
const list = [
    {
        title: "Efficiency",
        description:
            "Our advanced platform matches you with clinical trials that fit your specific health needs quickly and efficiently, reducing the time and effort required to find the right trial.",
        icon: <Efficiency />,
    },
    {
        title: "Diversity",
        description:
            "We offer a wide range of trials across various medical conditions and specialties, increasing your chances of finding a study that is perfectly suited to your situation.",
        icon: <Diversity />,
    },
    {
        title: "Transparency",
        description:
            "We provide clear and detailed information about each trial, including the processes, benefits, and potential risks, ensuring you can make informed decisions about your participation.",
        icon: <Transparency />,
    },
    {
        title: "Support",
        description:
            "Our dedicated support team is here to assist you at every stage of your trial journey, from initial matching to post-trial follow-ups, ensuring a smooth and supportive experience throughout.",
        icon: <Support />,
    },
];

export default function WhyTrials(): ReactElement {
    return (
        <section>
            <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  max-w-[760px] mx-auto text-center mb-8 lg:mb-16">
                Why Choose Our Clinical Trials?
            </h2>
            <div className="flex flex-wrap justify-center gap-20">
                {list?.map((item, index) => (
                    <div
                        key={index}
                        className={`text-center  w-full sm:w-[234px]`}
                    >
                        <span
                            className={
                                "mx-auto w-[85px] h-[85px] min-w-[85px] relative  block"
                            }
                        >
                            {item.icon}
                        </span>

                        <h3
                            className={
                                "font-bold lg:text-xl text-lg text-[#5DB0E2] mt-5"
                            }
                        >
                            {item.title}
                        </h3>
                        <div className={"text-base font-light mt-5"}>
                            {item.description}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
