import { useDomainContext } from "@hooks/useDomainContext";
import React, { ReactElement } from "react";
import ButtonArrow from "../buttonArrow";
import IconCircleTick from "../icons/circle-tick";
import styles from "./styles.module.scss";

export default function ListingModal({
    state,
    item,
}: {
    state: string | null | undefined;
    item: {
        brandName: string;
        bulletedDescription?: string[];
        logoUrl: string;
        slug: string;
        title: string;
    };
}): ReactElement {
    const { category } = useDomainContext();

    return (
        <div className={`${styles.offer}`}>
            <h3 className="font-bold text-xl mb-4 lg:px-4">
                Check {state} Top Auto Insurance Company
            </h3>
            <div className="p-6">
                <img className="mb-4" src={item.logoUrl} alt="offer logo" />
                <div>
                    <h2
                        className="mb-4 font-bold lg:text-lg"
                        dangerouslySetInnerHTML={{ __html: item.title }}
                    ></h2>
                </div>
                <div>
                    <ul className="flex flex-col">
                        {item.bulletedDescription?.map(
                            (feature: string, idx: number) => (
                                <li
                                    key={idx}
                                    className="text-sm font-light mb-3 flex items-start"
                                >
                                    <span className="children:h-3 children:w-3 mt-1">
                                        <IconCircleTick />
                                    </span>

                                    <span className="ml-2">{feature}</span>
                                </li>
                            ),
                        )}
                    </ul>
                </div>
                <div className={styles.button}>
                    <a
                        target="_blank"
                        href={`/${category?.slugAlias ?? ""}/offer/${
                            item.slug
                        }`}
                        rel="noreferrer"
                    >
                        Get the Best Deals
                        <ButtonArrow />
                    </a>
                </div>
            </div>
        </div>
    );
}
