import React, { ReactElement } from "react";
export default function IconCircleTick(): ReactElement {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.95 6.49101C11.737 7.76101 10.556 9.22502 9.33998 10.967C8.44298 10.09 5.52098 7.94601 4.17698 7.94601C4.01407 7.94004 3.85291 7.98137 3.71298 8.06501C3.6866 8.08183 3.66554 8.1058 3.65226 8.13413C3.63898 8.16246 3.63403 8.19398 3.63798 8.22502C3.67098 8.47902 3.87798 8.67301 4.06198 8.84401C4.12698 8.90401 4.18898 8.96201 4.23698 9.01701L4.24298 9.02301L4.24998 9.03001C6.27098 10.874 7.89098 12.914 9.06498 15.093C9.18498 15.316 9.42198 15.455 9.68298 15.455C9.95398 15.455 10.2 15.305 10.315 15.069C13.901 7.68902 17.535 4.45501 19.085 3.07301C19.748 2.48401 20.021 2.24001 19.999 1.99301C19.9957 1.96304 19.9844 1.9345 19.9663 1.91039C19.9481 1.88629 19.9239 1.8675 19.896 1.85601C19.8312 1.82969 19.7618 1.81676 19.692 1.81801C19.192 1.81801 18.175 2.36001 17.411 2.81401L17.408 2.81601C16.5876 3.32546 15.804 3.89185 15.063 4.51102C14.3223 5.13071 13.6171 5.79177 12.951 6.49101"
                fill="#F79426"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.214 6.10701C18.9723 5.53515 18.6777 4.98711 18.334 4.47001C18.0905 4.71227 17.8515 4.95898 17.617 5.21001C18.5226 6.64339 19.0022 8.30456 19 10C19 14.963 14.963 19 10 19C5.03701 19 1.00001 14.963 1.00001 10C1.00001 5.03701 5.03701 1.00001 10 1.00001C11.832 0.997979 13.6205 1.5582 15.124 2.60501C15.407 2.38801 15.693 2.18001 15.98 1.98301C14.2539 0.692182 12.1554 -0.00366489 10 1.45168e-05C8.68652 -0.00187916 7.3856 0.255921 6.1721 0.758589C4.95859 1.26126 3.85643 1.99887 2.92901 2.92901C1.99891 3.85647 1.26133 4.95863 0.758662 6.17213C0.255998 7.38563 -0.00182789 8.68653 1.09645e-05 10C-0.00193755 11.3135 0.255838 12.6144 0.758509 13.828C1.26118 15.0415 1.99882 16.1436 2.92901 17.071C3.85643 18.0012 4.95859 18.7388 6.1721 19.2414C7.3856 19.7441 8.68652 20.0019 10 20C11.3135 20.002 12.6144 19.7442 13.828 19.2415C15.0415 18.7388 16.1436 18.0012 17.071 17.071C18.0011 16.1436 18.7387 15.0414 19.2414 13.8279C19.744 12.6144 20.0019 11.3135 20 10C20 8.65001 19.736 7.34001 19.214 6.10701Z"
                fill="#F79426"
            />
        </svg>
    );
}
