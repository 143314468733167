import React, { ReactElement } from "react";
import MainImg from "./mainImg";
import SharedButton from "../sharedButton";

export default function DiscoverTheBenefits(): ReactElement {
    return (
        <section className=" flex flex-col lg:flex-row lg:gap-28 gap-16  lg:mt-28 mt-14 lg:mb-48 mb-20 ">
            <div className="max-w-[574px]  mx-auto lg:mx-0 order-2 ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] ">
                    Discover the Benefits of Participating in Our Clinical
                    Trials
                </h2>
                <p className="mt-5   lg:text-lg text-base lg:leading-8  font-light">
                    Did you know that you can play a vital role in advancing
                    medical breakthroughs? By participating in a clinical trial,
                    you can gain access to cutting-edge treatments that may not
                    be available elsewhere. At the same time, you'll be
                    contributing to the development of solutions and vaccines
                    for conditions that affect many people every day.
                </p>
                <div className="lg:mt-[50px] mt-7 text-center sm:text-left">
                    <SharedButton />
                </div>
            </div>
            <div className="w-[250px] h-[250px] md:h-[372px] md:w-[448px] md:min-w-[448px] relative mx-auto  order-2 ">
                <MainImg />
            </div>
        </section>
    );
}
