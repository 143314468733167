import React from "react";

function Cancers() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 57 58"
        >
            <path
                stroke="#5DB0E2"
                strokeDasharray="10 10"
                strokeLinecap="square"
                d="M2.5 0.5H56.5V54.5H2.5z"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M23.23 13.687l-1.93 4.518c2.958-1.294 6.016-1.546 8.717-1.293 2.21.206 4.204.751 5.744 1.36l-2.11-4.583c-4.087-2.093-8.505-.944-10.42-.002zm13.09 6.47c-1.461-.718-3.77-1.492-6.432-1.74-3.058-.286-6.53.126-9.705 2.053a.7.7 0 01-.848-.091.779.779 0 01-.177-.87l2.856-6.686a.743.743 0 01.33-.364c2.045-1.102 7.307-2.642 12.187 0 .139.076.25.197.319.344l3.079 6.686c.093.203.095.44.004.644-3.181 7.198-11.118 19.382-14.669 24.552a.705.705 0 01-.985.192L17.328 41.5a.757.757 0 01-.316-.495.781.781 0 01.125-.58c5.232-7.565 10.593-17.137 12.617-20.974a.709.709 0 01.983-.297c.35.2.479.66.286 1.026-1.982 3.756-7.133 12.962-12.27 20.486l3.737 2.548c3.6-5.286 10.661-16.203 13.83-23.058z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M25.351 18.076a.681.681 0 01.903.261l.154.277c.203.363.433.775.665 1.168.299.508.574.936.776 1.173a.623.623 0 01-.093.9.686.686 0 01-.939-.09c-.282-.332-.608-.849-.905-1.352-.24-.408-.48-.838-.683-1.202l-.15-.268a.627.627 0 01.272-.867zM19.39 20.088a.724.724 0 01.991.312c.931 1.8 2.41 4.526 4.208 7.635a.765.765 0 01-.256 1.033.722.722 0 01-1.005-.263 197.98 197.98 0 01-4.242-7.697.764.764 0 01.303-1.02zm13.884 11.55a.721.721 0 011.015.221c.826 1.307 4.697 6.864 6.57 9.49a.77.77 0 01-.199 1.082l-5.28 3.451a.72.72 0 01-.965-.163c-1.69-2.153-3.419-4.616-5.088-7.153a.767.767 0 01.196-1.046.721.721 0 011.019.202c1.519 2.308 3.08 4.541 4.606 6.525l4.065-2.657c-1.983-2.799-5.366-7.664-6.154-8.91a.766.766 0 01.215-1.042z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Cancers;
