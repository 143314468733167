import React from "react";

function Transparency() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 85 85"
        >
            <circle cx="42.5" cy="42.5" r="42.5" fill="#5DB0E2"></circle>
            <path
                fill="#fff"
                d="M39.45 23.78c-8.086 0-14.67 6.584-14.67 14.671s6.584 14.67 14.67 14.67c8.088 0 14.671-6.583 14.671-14.67s-6.583-14.67-14.67-14.67zm0 27.697c-7.182 0-13.026-5.843-13.026-13.026 0-7.184 5.843-13.027 13.027-13.027 7.184 0 13.027 5.843 13.027 13.026 0 7.184-5.843 13.027-13.027 13.027z"
            ></path>
            <path
                fill="#fff"
                d="M39.45 27.068c-5.325 0-10.002 3.78-11.127 8.983a.824.824 0 001.61.345c.962-4.454 4.965-7.684 9.518-7.684.452 0 .822-.37.822-.822a.825.825 0 00-.822-.822z"
            ></path>
            <path
                fill="#fff"
                d="M62.907 57.97l-4.865-4.865c-.313-.313-.855-.313-1.16 0L55.388 54.6l-3-3c3.411-3.353 5.523-8.005 5.523-13.15C57.91 28.276 49.634 20 39.45 20 29.269 20 21 28.276 21 38.45c0 10.176 8.276 18.452 18.45 18.452 4.439 0 8.524-1.578 11.704-4.2l3.066 3.066-1.496 1.495a.818.818 0 000 1.16l4.866 4.865a3.705 3.705 0 002.638 1.093c.994 0 1.94-.386 2.646-1.093l.033-.033A3.71 3.71 0 0064 60.608a3.71 3.71 0 00-1.093-2.646v.008zM22.644 38.451c0-9.27 7.545-16.807 16.807-16.807 9.262 0 16.815 7.545 16.815 16.807 0 9.262-7.544 16.807-16.815 16.807-9.27 0-16.807-7.536-16.807-16.807zm39.104 23.645l-.032.033c-.79.789-2.17.789-2.96 0l-4.281-4.282 2.991-2.992 4.282 4.282c.395.395.617.92.617 1.48a2.05 2.05 0 01-.617 1.48z"
            ></path>
        </svg>
    );
}

export default Transparency;
