import React from "react";

function FinancialCompensationHealth() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 449 320"
        >
            <path
                fill="#DFEEFA"
                d="M429.861 244.231C490.289 134.24 399.932 3.783 264.604 9.161c-40.794 1.622-81.02 16.268-111.162 55.71-69.74 91.26-132.37 69.112-150.756 140.818-18.39 71.706 59.888 148.425 165.959 63.538 77.529-62.045 151.904 117.299 240.853 8.804 8.85-10.796 14.403-22.951 20.363-33.8z"
            ></path>
            <path
                fill="#B3D3F5"
                d="M324.455 208.237c6.811 12.069 5.449 22.03 3.198 28.16 5.521-6.298 11.102-12.023 16.397-17.086-13.67-22.111 2.418-32.589 2.418-32.589 6.383 11.808 5.131 21.502 2.897 27.631l.073-.068a321.315 321.315 0 0120.423-17.047c-10.227-25.243 8.166-32.701 8.166-32.701 4.371 13.98.28 23.754-3.394 29.112 6.502-4.798 11.437-8.054 13.719-9.519 15.529-16.611 32.673-10.205 32.673-10.205-12.487 17.179-24.785 14.781-30.266 12.428-1.786 1.129-7.09 4.56-14.377 9.944 18.591-1.521 27.308 11.045 27.308 11.045-20.551 7.094-29.22-2.372-32.208-7.351a317.22 317.22 0 00-19.948 16.673c-.74.672-1.492 1.367-2.248 2.07 18.929-6.191 30.574 5.156 30.574 5.156-20.336 11.759-30.754 1.844-33.833-2.084-5.554 5.321-11.421 11.381-17.188 18.062 18.244-5.823 29.686 4.924 29.686 4.924-18.924 11.409-29.425 3.478-33.226-.723-12.088 14.682-23.35 32.066-29.856 51.149a1.589 1.589 0 01-3.008-1.023c6.702-19.658 18.312-37.509 30.73-52.526-14.987-22.399 1.289-33.432 1.289-33.432h.001zM275.7 135.348c13.791 14.243 15.536 28.551 14.659 37.873 5.328-10.75 10.948-20.728 16.413-29.682-26.912-25.659-8.52-46.067-8.52-46.067 13.105 14.039 14.904 27.936 14.052 37.25l.075-.121c7.675-11.891 15.291-22.408 21.985-31.099-23.3-31.26-.629-48.323-.629-48.323 11.12 17.785 9.03 32.83 5.908 41.602 7.229-9.029 12.855-15.347 15.472-18.211 15.388-28.707 41.38-26.092 41.38-26.092-10.983 28.382-28.83 29.553-37.25 28.293-2.054 2.218-8.127 8.914-16.224 19.042 25.104-8.904 41.708 5.332 41.708 5.332-25.782 17.347-41.192 7.392-47.127 1.581a454.922 454.922 0 00-21.463 30.407 386.927 386.927 0 00-2.351 3.692c23.873-15.503 44.072-4.028 44.072-4.028-23.786 23.738-41.772 13.798-47.452 9.478-5.728 9.408-11.621 19.955-17.15 31.325 23.061-14.743 42.763-4.025 42.763-4.025-21.967 22.736-39.345 15.578-46.119 11.143-11.341 24.774-20.56 52.992-22.595 81.828a2.27 2.27 0 01-3.128 1.933 2.266 2.266 0 01-1.396-2.252c2.098-29.704 11.626-58.698 23.3-84.057-28.834-25.577-10.386-46.822-10.386-46.822h.003z"
            ></path>
            <path
                fill="#D4E8FC"
                d="M78.336 253.336l-39.002-32.443c-5.397-4.634-1.967-13.134 6.119-15.155L162.44 176.51l-84.105 76.826z"
            ></path>
            <path
                fill="#BFCEF5"
                d="M87.791 255.418l116.978-32.113c3.823-1.121 6.315-4.559 6.3-8.685l-.728-188.274c-.026-7.002-6.986-12.65-13.569-11.013L79.786 44.432c-4.014.997-6.682 4.514-6.666 8.785l.748 193.479c.03 7.167 7.288 10.67 13.926 8.722h-.003z"
            ></path>
            <path
                fill="#1D5F7A"
                d="M93.614 258.175l116.978-32.113c3.823-1.121 6.316-4.559 6.3-8.685l-.728-188.274c-.026-7.002-6.986-12.65-13.569-11.013L85.61 47.189c-4.014.998-6.683 4.514-6.667 8.785l.749 193.479c.029 7.167 7.287 10.67 13.925 8.722h-.003z"
            ></path>
            <path
                fill="#DFEEFA"
                d="M88.163 246.146l120.227-32.401-.707-182.607L87.445 60.069l.718 186.077z"
            ></path>
            <path
                fill="#7789C4"
                d="M122.549 33.434l6.75-2.155-.016-3.215c-.042-8.906 7.102-18.484 15.876-21.286 8.772-2.8 15.983 2.197 16.025 11.102l.016 3.216 6.75-2.155c6.177-1.972 11.255 1.547 11.283 7.819.029 6.27-5.001 13.016-11.178 14.988l-45.402 14.49c-6.177 1.972-11.254-1.547-11.283-7.818-.028-6.272 5.002-13.017 11.179-14.989v.003zm22.089-10.21l1.194-.382c2.168-.69 3.936-3.06 3.925-5.26-.01-2.202-1.793-3.436-3.962-2.744l-1.194.382c-2.168.691-3.935 3.061-3.925 5.26.011 2.203 1.794 3.436 3.962 2.745z"
            ></path>
            <path
                fill="#BBD0F3"
                d="M126.186 35.361l6.753-1.838-.016-3.215c-.042-8.906 7.104-18.149 15.878-20.534 8.775-2.388 15.989 2.945 16.031 11.85l.015 3.216 6.753-1.838c6.18-1.68 11.257 2.074 11.286 8.345.029 6.271-5.001 12.778-11.181 14.46l-45.412 12.359c-6.18 1.68-11.257-2.074-11.286-8.345-.028-6.272 5.002-12.779 11.181-14.46h-.002zm22.094-9.17l1.194-.324c2.168-.59 3.936-2.875 3.925-5.077-.01-2.203-1.793-3.52-3.961-2.93l-1.194.324c-2.168.59-3.936 2.875-3.925 5.077.01 2.203 1.793 3.52 3.961 2.93z"
            ></path>
            <path
                fill="#D4E8FC"
                d="M268.674 293.747c-8.219-.134-14.819-4.01-14.743-8.656.076-4.646 6.8-8.303 15.019-8.168 8.219.135 14.82 4.011 14.744 8.657-.077 4.645-6.801 8.302-15.02 8.167z"
            ></path>
            <path
                stroke="#0E2E81"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="10"
                d="M268.214 276.082l-20.115-15.271c-9.565-9.84-9.607-17.897-9.974-29.023l-.442-57.083"
            ></path>
            <path
                fill="#245FE0"
                d="M292.65 279.777l-.228 6.551c-.162 4.643-6.954 8.259-15.171 8.076-8.217-.184-14.747-4.096-14.588-8.741l.207-5.991 29.777.102.003.003z"
            ></path>
            <path
                fill="#5DB0E2"
                d="M277.634 288.42c-8.218-.135-14.819-4.011-14.743-8.656.076-4.646 6.801-8.303 15.019-8.168 8.219.135 14.82 4.01 14.744 8.656-.076 4.646-6.801 8.303-15.02 8.168z"
            ></path>
            <path
                fill="#fff"
                d="M277.714 284.401c-4.844-.079-8.735-2.362-8.69-5.1.045-2.737 4.008-4.891 8.852-4.812 4.844.08 8.734 2.363 8.69 5.1-.045 2.737-4.009 4.892-8.852 4.812zM238.013 168.811l1.529-.354c12.367-2.867 22.883-7.051 23.04-20.198l.029-41.438c.026-2.163-1.637-4.176-3.674-3.705l-11.288 2.27-3.409-6.766 14.862-2.64c4.378-1.015 11.095 4.484 11.04 9.137l-.542 45.281c-.158 13.145-10.254 21.45-22.621 24.315l-16.591 3.847 7.628-9.752-.003.003z"
            ></path>
            <path
                fill="#0E2E81"
                d="M238.013 168.811l1.529-.353c12.367-2.868 22.883-7.052 23.04-20.199l.005-9.236c1.543 1.812 7.136.938 7.157-.9l-.147 12.275c-.157 13.145-10.254 21.451-22.621 24.315l-16.591 3.847 7.628-9.752v.003zM242.765 107.156l9.042-2.105c2.008-.469 3.04-2.658 2.289-4.868-.752-2.21-3.009-3.631-5.017-3.165l-9.042 2.105c-2.008.469-3.04 2.658-2.289 4.868.752 2.21 3.009 3.632 5.017 3.165z"
            ></path>
            <path
                fill="#245FE0"
                d="M225.463 160.508l19.243-4.91c1.238-.317 2.377-.086 3.278.545h.005l.021.015c.207.147.398.317.579.506l4.894 4.729-3.517 4.093-.005.568c-.026 3.543-2.448 7.054-5.384 7.805l-8.305 2.119-9.529 6.844-4.661-4.577v-.005c-1.249-.859-2.053-2.49-2.04-4.553l.04-5.376c.026-3.543 2.448-7.054 5.383-7.806l-.002.003z"
            ></path>
            <path
                fill="#fff"
                d="M224.159 172.802c-7.39 3.063-17.657-7.219-17.306-20.566l.872-33.137c.058-2.197 1.663-4.43 3.582-4.988l10.134-2.686-3.067-7.196-9.963 2.899c-4.132 1.202-7.58 6.004-7.706 10.73l-1.029 39.068c-.39 14.833 16.41 24.645 28.08 21.251 1.129-.749.435-4.213-3.6-5.378l.003.003z"
            ></path>
            <path
                fill="#0E2E81"
                d="M222.182 171.073c-7.107 1.003-15.651-6.703-15.332-18.834-3.155 1.725-6.611.885-7.02-1.173l-.154 5.862c-.39 14.834 16.41 24.646 28.08 21.252.521-.346.676-1.757.296-3.291-.644-2.601-3.192-4.193-5.868-3.816h-.002zM231.341 109.757l-9.345 2.081c-2.077.464-4.289-1.089-4.915-3.448-.626-2.36.563-4.669 2.639-5.132l9.346-2.082c2.076-.464 4.289 1.089 4.914 3.448.626 2.36-.562 4.669-2.639 5.133z"
            ></path>
            <path
                fill="#5DB0E2"
                d="M230.302 183.582l19.243-4.91c2.935-.749 5.36-4.26 5.383-7.805l.04-5.376c.026-3.543-2.357-5.829-5.292-5.08l-19.244 4.91c-2.935.748-5.36 4.26-5.383 7.805l-.039 5.376c-.027 3.543 2.356 5.829 5.292 5.08zM153.483 188.542c20.231-13.247 35.187-29.104 34.967-50.021l-.515-49.086-36.52-6.878-36.177 25.881.516 49.086c-.338 7.022 14.092 24.922 37.729 31.018z"
            ></path>
            <path
                fill="#fff"
                d="M181.53 98.906l-29.209-5.501-28.934 20.699.411 39.256c1.476 8.209 11.353 19.856 29.167 26.981 13.642-12.757 29.149-25.452 28.973-42.181l-.411-39.256.003.002z"
            ></path>
            <path
                fill="#5DB0E2"
                d="M148.697 151.119l11.864-3.04-.194-33.286-11.861 3.04.191 33.286z"
            ></path>
            <path
                fill="#5DB0E2"
                d="M170.454 134.994l-.071-12.43-31.773 8.144.071 12.427 31.773-8.141z"
            ></path>
            <path
                fill="#D4E8FC"
                d="M199.815 316.095l34.401-9.534c4.315-1.196 4.664-4.446.775-7.222l-49.662-35.454c-3.888-2.775-10.602-4.069-14.917-2.872l-34.402 9.534c-4.315 1.196-4.663 4.446-.775 7.221l49.662 35.455c3.889 2.775 10.602 4.069 14.918 2.872z"
            ></path>
            <path
                fill="#7CA4E6"
                d="M139.008 273.888v-.047l.155-3.171 2.113.599a8.228 8.228 0 011.194-.437l34.402-9.534c4.315-1.196 11.026.097 14.917 2.873l49.662 35.451 2.857.809-.163 3.383h-.005c-.133 1.315-1.299 2.433-3.464 3.033l-34.402 9.533c-4.315 1.197-11.026-.096-14.917-2.872l-49.662-35.454c-1.802-1.286-2.681-2.671-2.692-3.91h-.01l.013-.256h.002z"
            ></path>
            <path
                fill="#BBD0F3"
                d="M206.429 313.217l34.401-9.533c4.316-1.197 4.664-4.447.775-7.222l-49.662-35.454c-3.888-2.776-10.562-3.839-14.878-2.642l-34.441 9.303c-4.315 1.197-4.663 4.446-.775 7.222l49.662 35.454c3.889 2.776 10.603 4.069 14.918 2.872z"
            ></path>
            <path
                stroke="#7CA4E6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.9"
                d="M224.502 308.208l-62.757-45.488"
            ></path>
            <path
                fill="#7CA4E6"
                d="M146.406 272.306c-.15 2.189 3.095 4.072 7.247 4.208 4.153.136 7.636-1.526 7.785-3.715.149-2.189-3.097-4.072-7.248-4.208-4.15-.136-7.635 1.526-7.784 3.715z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M153.996 268.35a13.448 13.448 0 013.164-.262c1.123.037 2.173.204 3.113.469l4.252 1.49-.128 1.903c.011.115.018.231.011.346-.15 2.189-3.635 3.852-7.785 3.715-4.15-.136-7.397-2.018-7.248-4.207l.139-2.035 4.482-1.417v-.002z"
            ></path>
            <path
                fill="#BACCF2"
                d="M153.996 268.35a13.448 13.448 0 013.164-.262c1.123.037 2.173.204 3.113.469-7.285 2.335-7.837 4.839-3.653 7.454-4.15-.136-7.397-2.018-7.248-4.207l.139-2.035 4.483-1.417.002-.002z"
            ></path>
            <path
                fill="#fff"
                d="M149.503 269.918c-.149 2.189 3.098 4.072 7.248 4.208 4.15.136 7.636-1.526 7.785-3.715.149-2.19-3.095-4.072-7.248-4.208-4.153-.137-7.636 1.526-7.785 3.715z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M152.729 268.324l7.62 3.425c.225.102.636.06.916-.092.281-.152.325-.356.1-.458l-7.62-3.425c-.225-.102-.636-.061-.916.091s-.325.356-.1.459z"
            ></path>
            <path
                fill="#7CA4E6"
                d="M169.679 287.713c-4.154-.08-7.487-1.92-7.445-4.111.042-2.19 3.444-3.901 7.598-3.821 4.154.079 7.487 1.92 7.445 4.11-.042 2.191-3.444 3.902-7.598 3.822z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M169.817 279.536a13.444 13.444 0 013.163-.262c1.124.037 2.174.204 3.114.469l4.252 1.49-.128 1.903c.01.115.018.231.01.346-.149 2.189-3.634 3.852-7.784 3.715-4.151-.136-7.398-2.018-7.248-4.207l.138-2.035 4.483-1.417v-.002z"
            ></path>
            <path
                fill="#BACCF2"
                d="M169.817 279.536a13.448 13.448 0 013.164-.262c1.123.037 2.173.204 3.113.469-7.285 2.335-7.837 4.839-3.653 7.454-4.15-.136-7.397-2.018-7.248-4.207l.139-2.035 4.483-1.417.002-.002z"
            ></path>
            <path
                fill="#fff"
                d="M172.778 285.325c-4.154-.08-7.488-1.92-7.446-4.111.042-2.19 3.444-3.902 7.598-3.822 4.154.08 7.487 1.92 7.445 4.111-.042 2.19-3.443 3.901-7.597 3.822z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M168.55 279.512l7.62 3.425c.225.102.636.061.916-.091s.325-.356.1-.459l-7.62-3.425c-.225-.102-.636-.06-.916.092-.281.152-.325.356-.1.458z"
            ></path>
            <path
                fill="#7CA4E6"
                d="M185.502 298.9c-4.154-.08-7.487-1.92-7.445-4.111.042-2.19 3.444-3.901 7.598-3.822 4.154.08 7.487 1.921 7.445 4.111-.042 2.191-3.444 3.902-7.598 3.822z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M185.636 290.725a13.435 13.435 0 013.163-.262c1.123.036 2.173.204 3.113.468l4.252 1.49-.128 1.904c.011.115.018.23.011.346-.15 2.189-3.635 3.851-7.785 3.715-4.15-.136-7.397-2.019-7.248-4.208l.139-2.034 4.483-1.417v-.002z"
            ></path>
            <path
                fill="#BACCF2"
                d="M185.636 290.725a13.435 13.435 0 013.163-.262c1.123.036 2.173.204 3.113.468-7.285 2.336-7.837 4.839-3.653 7.455-4.15-.136-7.397-2.019-7.248-4.208l.139-2.034 4.483-1.417.003-.002z"
            ></path>
            <path
                fill="#fff"
                d="M188.598 296.511c-4.154-.079-7.487-1.92-7.445-4.11.042-2.191 3.443-3.902 7.597-3.822 4.154.08 7.488 1.92 7.446 4.111-.042 2.19-3.444 3.901-7.598 3.821z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M184.371 290.699l7.62 3.425c.225.102.636.06.916-.092s.325-.356.1-.458l-7.62-3.425c-.225-.102-.637-.061-.917.091s-.324.356-.099.459z"
            ></path>
            <path
                fill="#7CA4E6"
                d="M193.865 305.87c-.149 2.189 3.096 4.072 7.248 4.208 4.151.136 7.636-1.527 7.785-3.716.149-2.189-3.095-4.072-7.248-4.208-4.15-.136-7.635 1.527-7.785 3.716z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M201.456 301.911a13.444 13.444 0 013.163-.262c1.124.037 2.174.204 3.114.469l4.252 1.49-.128 1.903c.01.115.018.231.01.346-.149 2.189-3.634 3.852-7.784 3.715-4.151-.136-7.398-2.018-7.248-4.207l.139-2.035 4.482-1.417v-.002z"
            ></path>
            <path
                fill="#BACCF2"
                d="M201.456 301.911a13.448 13.448 0 013.164-.262c1.123.037 2.173.204 3.113.469-7.285 2.335-7.837 4.839-3.653 7.454-4.15-.136-7.397-2.018-7.248-4.207l.139-2.035 4.483-1.417.002-.002z"
            ></path>
            <path
                fill="#fff"
                d="M204.421 307.701c-4.154-.08-7.487-1.921-7.445-4.111.042-2.191 3.444-3.902 7.598-3.822 4.154.08 7.487 1.92 7.445 4.111-.042 2.19-3.444 3.901-7.598 3.822z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M200.189 301.887l7.62 3.425c.225.102.636.061.916-.091s.325-.356.1-.459l-7.62-3.425c-.225-.102-.636-.06-.916.092-.281.152-.325.356-.1.458z"
            ></path>
            <path
                fill="#7CA4E6"
                d="M178.74 269.467c-4.154-.08-7.488-1.92-7.446-4.111.042-2.19 3.444-3.901 7.598-3.822 4.154.08 7.487 1.92 7.445 4.111-.042 2.19-3.443 3.902-7.597 3.822z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M178.877 261.29a13.499 13.499 0 013.163-.261c1.124.036 2.174.204 3.114.468l4.252 1.49-.128 1.904c.01.115.018.23.01.346-.149 2.189-3.634 3.851-7.784 3.715-4.151-.136-7.398-2.019-7.248-4.208l.138-2.034 4.483-1.417v-.003z"
            ></path>
            <path
                fill="#BACCF2"
                d="M178.877 261.29a13.499 13.499 0 013.163-.261c1.124.036 2.174.204 3.114.468-7.285 2.336-7.837 4.839-3.653 7.455-4.15-.136-7.397-2.019-7.248-4.208l.139-2.034 4.483-1.417.002-.003z"
            ></path>
            <path
                fill="#fff"
                d="M181.835 267.079c-4.154-.08-7.487-1.921-7.445-4.111.042-2.191 3.444-3.902 7.598-3.822 4.154.08 7.487 1.92 7.445 4.111-.042 2.19-3.444 3.901-7.598 3.822z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M177.61 261.264l7.62 3.425c.225.102.636.06.916-.091.28-.152.325-.357.1-.459l-7.62-3.425c-.225-.102-.636-.06-.917.092-.28.152-.324.356-.099.458z"
            ></path>
            <path
                fill="#7CA4E6"
                d="M193.988 280.3c-4.154-.08-7.487-1.921-7.445-4.111.042-2.191 3.444-3.902 7.598-3.822 4.154.08 7.487 1.92 7.445 4.111-.042 2.19-3.444 3.901-7.598 3.822z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M194.122 272.123a13.435 13.435 0 013.163-.262c1.123.037 2.173.204 3.113.469l4.253 1.49-.128 1.903c.01.116.018.231.01.346-.149 2.189-3.634 3.852-7.785 3.716-4.15-.137-7.397-2.019-7.248-4.208l.139-2.035 4.483-1.416v-.003z"
            ></path>
            <path
                fill="#BACCF2"
                d="M194.122 272.123a13.44 13.44 0 013.163-.262c1.124.037 2.174.204 3.114.469-7.285 2.336-7.838 4.839-3.653 7.455-4.151-.137-7.397-2.019-7.248-4.208l.139-2.035 4.482-1.416.003-.003z"
            ></path>
            <path
                fill="#fff"
                d="M197.084 277.911c-4.154-.08-7.487-1.92-7.445-4.111.042-2.19 3.443-3.901 7.597-3.821 4.154.079 7.488 1.92 7.446 4.11-.042 2.191-3.444 3.902-7.598 3.822z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M192.855 272.097l7.619 3.425c.226.102.637.06.917-.092s.325-.356.099-.458l-7.619-3.425c-.226-.102-.637-.06-.917.092-.28.151-.324.356-.099.458z"
            ></path>
            <path
                fill="#7CA4E6"
                d="M210.442 292.177c-4.154-.08-7.487-1.921-7.445-4.111.042-2.191 3.443-3.902 7.597-3.822 4.154.08 7.488 1.92 7.446 4.111-.042 2.19-3.444 3.901-7.598 3.822z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M210.579 284a13.499 13.499 0 013.163-.261c1.124.036 2.174.204 3.114.468l4.252 1.49-.128 1.904c.01.115.018.23.01.345-.149 2.19-3.634 3.852-7.785 3.716-4.15-.136-7.397-2.019-7.247-4.208l.138-2.034 4.483-1.417V284z"
            ></path>
            <path
                fill="#BACCF2"
                d="M210.579 284a13.499 13.499 0 013.163-.261c1.124.036 2.174.204 3.114.468-7.285 2.336-7.838 4.839-3.653 7.455-4.15-.136-7.397-2.019-7.248-4.208l.139-2.034 4.482-1.417.003-.003z"
            ></path>
            <path
                fill="#fff"
                d="M213.541 289.785c-4.154-.079-7.488-1.92-7.446-4.11.042-2.191 3.444-3.902 7.598-3.822 4.154.08 7.487 1.92 7.445 4.111-.042 2.19-3.443 3.901-7.597 3.821z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M209.312 283.974l7.62 3.425c.225.102.636.06.916-.091.28-.152.325-.357.1-.459l-7.62-3.425c-.225-.102-.636-.06-.917.092-.28.152-.324.356-.099.458z"
            ></path>
            <path
                fill="#7CA4E6"
                d="M226.201 303.027c-4.154-.08-7.487-1.92-7.445-4.111.042-2.19 3.443-3.901 7.597-3.822 4.154.08 7.488 1.921 7.446 4.111-.042 2.191-3.444 3.902-7.598 3.822z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M226.337 294.852a13.44 13.44 0 013.163-.262c1.124.036 2.174.204 3.114.468l4.252 1.49-.128 1.904c.01.115.018.23.01.346-.149 2.189-3.634 3.851-7.785 3.715-4.15-.136-7.397-2.019-7.248-4.208l.139-2.034 4.483-1.417v-.002z"
            ></path>
            <path
                fill="#BACCF2"
                d="M226.337 294.852a13.444 13.444 0 013.163-.262c1.124.036 2.174.204 3.114.468-7.285 2.336-7.837 4.839-3.653 7.455-4.15-.136-7.397-2.019-7.248-4.208l.139-2.034 4.483-1.417.002-.002z"
            ></path>
            <path
                fill="#fff"
                d="M229.297 300.638c-4.154-.079-7.488-1.92-7.446-4.11.042-2.191 3.444-3.902 7.598-3.822 4.154.08 7.487 1.92 7.445 4.111-.042 2.19-3.443 3.901-7.597 3.821z"
            ></path>
            <path
                fill="#DEE8FF"
                d="M225.07 294.825l7.62 3.425c.225.102.636.061.916-.091s.325-.356.1-.459l-7.62-3.425c-.225-.102-.637-.06-.917.092s-.324.356-.099.458z"
            ></path>
            <path
                fill="#24386B"
                fillRule="evenodd"
                d="M48.105 164.959s-2.804 6.096-9.743 7.494c-5.218 1.05-7.313 4.824-9.508 10.498-3.508 9.078-12.521 4.737-13.794 18.19-.78 8.249 6.237 15.256 19.597 8.411 13.36-6.845 38.06 15.661 36.24-23.333-.228-4.855-5.164-4.415-4.742-8.866.46-4.884-5.944-12.752-5.944-12.752l-12.105.361v-.003z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#D4E8FC"
                d="M30.96 297.137c6.75-4.158 18.625-5.022 26.522-1.932 1.349.526 2.488 1.136 3.425 1.804 4.818-1.841 11.474-1.993 16.154-.162 5.548 2.173 6.2 6.302 1.458 9.222-4.559 2.807-12.435 3.472-17.968 1.592-.312.228-.639.45-.992.668-6.75 4.158-18.626 5.022-26.523 1.932-7.897-3.092-8.827-8.968-2.074-13.126l-.002.002z"
            ></path>
            <path
                fill="#DE5840"
                d="M105.172 183.409c.322 1.199-.206 2.365-1.173 2.59l-3.833-.053c-.969.226-1.92-2.361-.178-3.197l2.754-.573a3.37 3.37 0 011.098-.058c.683.087 1.081.346 1.335 1.291h-.003z"
            ></path>
            <path
                fill="#fff"
                d="M62.245 175.056l17.769 9.361 19.61-1.547.057 3.844c-.123 1.107-15.13 7.206-19.484 8.444a5.46 5.46 0 01-3.082-.029c-6.37-1.919-22.498-10.858-22.498-10.858l7.63-9.218-.002.003z"
            ></path>
            <path
                fill="#24386B"
                d="M44.455 297.585l-.1 4.088c-.036.429-.46 2.689-.643 3.079-.532 1.126-1.401 1.576-2.242 1.631-1.113.074-3.343-1.901-2.54-4.341.702-2.126 2.1-5.517 2.1-5.517 1.569.725 2.294 1.055 3.425 1.06zM73.766 299.256l-6.03-6.392c-.903-.422-2.553-.521-3.878-.223-.594.134-.893.545-.9 1.147l-.092 1.121c-.097 1.875-.296 2.501.892 3.058l7.073 3.305c1.472 1.034 5.447.296 2.933-2.014l.002-.002z"
            ></path>
            <path
                fill="#DE5840"
                d="M67.545 293.553s3.451 3.815 3.045 4.116c-1.683 1.249-5.975-2.315-5.975-2.315s2.168-1.966 2.93-1.801z"
            ></path>
            <path
                fill="#DE5840"
                d="M64.615 295.354c1.579-.076 3.03.681 3.087-1.626-.76-12.862 1.514-23.891.812-35.912-.788-13.499-1.791-13.677-3.03-34.368l-12.438 2.7c3.153 14.752 5.188 33.495 5.188 33.495-2.512 14.614 2.972 18.112 6.384 35.711h-.003zM41.08 297.687c.943 2.289 2.823 2.941 3.229.668 1.186-12.828 5.436-23.341 6.554-35.334 1.254-13.464.288-13.789 2.18-34.43l-12.701.796c.895 15.059.084 33.893.084 33.893-4.685 14.069-.066 16.494.657 34.407h-.003z"
            ></path>
            <path
                fill="#5DB0E2"
                d="M65.346 208.19s8.583-1.943 7.13 45.63c0 0-4.837 5.085-13.135 7.211-7.821 2.003-16.004-5.09-23.286-6.481 0 0 3.944-9.08 3.436-13.343s-3.022-29.16 3.98-29.945c7.002-.783 21.875-3.072 21.875-3.072z"
            ></path>
            <path
                fill="#5DB0E2"
                d="M60.169 174.31a6.677 6.677 0 00-1.925-.186c-2.993.154-6.813-.663-7.122-.456l-5.934 2.417c-10.738 3.705-10.877 6.827-7.98 12.54 0 0 6.53 9.861 6.805 14.061.238 3.632-2.818 9.369-3.396 10.555-.75 1.54 4.467 10.809 18.423 8.702 10.993-1.661 9.576-6.167 9.128-12.171-.283-3.779-2.953-13.792-3.006-16.792-.052-3.001 2.163-5.332.39-10.092-.948-2.545-1.537-4.554-1.877-5.871a3.288 3.288 0 00-2.234-2.322l-1.27-.388-.002.003z"
            ></path>
            <path
                fill="#DE5840"
                d="M53.172 176.292l.346-.338c.872.241 1.652.142 1.843-.722l1.068-4.873c.189-.864-.369-1.77-1.24-2.011l-.26-.071c-.872-.241-2.25-.063-2.438.801l-1.35 5.138c-.19.864 1.16 1.835 2.034 2.076h-.003z"
            ></path>
            <path
                fill="#63342E"
                d="M55.833 172.964s-2.132-.202-3.402-2.404c0 0 1.22 3.231 3.148 3.724l.256-1.32h-.002z"
            ></path>
            <path
                fill="#DE5840"
                d="M51.842 162.652c.835-1.298 2.04-1.508 3.116-.733 1.105-.442 2.388.259 3.283 1.995 1.25 2.417 1.451 6.214.233 8.11-.531.827-1.335.992-2.086.995a5.61 5.61 0 01-2.077-.419c-2.388-.961-2.446-.605-3.276-2.215-1.249-2.417-.41-5.839.807-7.735v.002z"
            ></path>
            <path
                fill="#24386B"
                d="M53.355 163.54c-1.005 2.289-1.432 7.633-1.6 8.337 0 0-4.867-.432-3.898-5.897 1.207-6.805 4.901-3.113 4.901-3.113l.595.673h.002z"
            ></path>
            <path
                fill="#24386B"
                d="M51.638 164.383c2.723-.943 4.97-1.681 8.887 2.016.343-.565-.346-2.574-1.17-3.257-1.724-1.427-2.59-2.197-4.52-2.739l-.471-.078c-2.839-.482-4.418.528-5.188 3.244-.068.238-.62 1.314-.646 1.516l.932-.254c-.223 1.618.547-.77 2.173-.448h.003z"
            ></path>
            <path
                fill="#fff"
                d="M56.888 171.437c.552-.128 1.081-.913 1.18-1.754l-2 .464c-.1.84.265 1.416.817 1.288l.003.002z"
            ></path>
            <path
                fill="#DE5840"
                d="M50.18 169.083a1.396 1.396 0 002.77.33 1.396 1.396 0 00-2.77-.33z"
            ></path>
            <path
                fill="#fff"
                d="M55.76 173.338l1.432.456s.688 2.548-.506 3.522l-2.12-1.383s1.337-1.249 1.19-2.592l.003-.003z"
            ></path>
            <path
                fill="#fff"
                d="M54.568 175.933l-1.343 2.103s-3.774-1.278-3.808-3.734l1.566-.812s1.864 2.627 3.585 2.446v-.003z"
            ></path>
            <path
                fill="#DE5840"
                d="M44.311 298.355s-.76 4.396-2.602 4.019c-1.844-.379-.626-4.684-.626-4.684l3.228.668v-.003zM27.64 234.014c-1.236-.105-2.153-1-2.038-1.985l1.354-3.587c.118-.988 2.872-1.003 3.066.921l-.398 2.786c-.052.362-.152.72-.32 1.05-.313.613-.693.901-1.667.817l.002-.002z"
            ></path>
            <path
                fill="#fff"
                d="M45.623 189.578L35.251 210.01l-5.218 18.966-3.635-1.254c-.997-.493-1.626-16.677-1.309-21.197.073-1.047.628-1.938 1.076-2.888 3.216-6.811 9.757-21.686 9.757-21.686l9.701 7.625v.002zM62.245 175.056s3.81 1.508 4.894 14.2 6.565 25.907 6.662 58.984c0 0-5.224 5.763-10.168 6.046 0 0-1.233-52.024-1.464-59.84-.23-7.816.223-10.484-3.925-20.325 0 0 2.448.116 4.001.933v.002z"
            ></path>
            <path
                fill="#fff"
                d="M47.702 174.857s5.253 6.528 6.007 19.068c.754 12.54-2.587 61.589-2.587 61.589s-11.307-2.309-16.81-6.138c0 0 2.974-26.619 3.595-36.216.62-9.596.777-23.676.777-23.676l-2.76-7.531s.684-2.529 4.34-3.969c3.654-1.441 7.44-3.127 7.44-3.127h-.002z"
            ></path>
            <path
                stroke="#B4C5D6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M39.517 184.527l-3.708 13.174M63.638 180.008l1.797 4.519"
            ></path>
        </svg>
    );
}

export default FinancialCompensationHealth;
