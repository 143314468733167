import React, { ReactElement } from "react";
import ExpertCareComprehensiveHealthImg from "./images/expertCareComprehensiveHealth";
import FinancialCompensationHealth from "./images/financialCompensationHealth";

const list = [
    {
        title: "Expert Care & Comprehensive Health Assessments",
        description:
            "You'll benefit from the expertise of top medical professionals and researchers. The health data collected during the trial can provide you with valuable insights into your overall health, helping you make informed decisions about your future healthcare.",
        icon: <ExpertCareComprehensiveHealthImg />,
    },
    {
        title: "Financial Compensation & Health Benefits",
        description:
            "As a participant, you may receive financial compensation to cover expenses like travel, lodging, and time off work. This is done to reduce financial stress and encourage participation. You’ll also gain access to new treatments that might not be available to the public yet, offering hope for better health outcomes.",
        icon: <FinancialCompensationHealth />,
    },
];

export default function ExpertCareComprehensiveHealth(): ReactElement {
    return (
        <section
            className={`mb-10 lg:mb-16 flex flex-wrap justify-between gap-16  `}
        >
            {list?.map((item, index) => (
                <div key={index} className={`mx-auto  max-w-[550px]  `}>
                    <span
                        className={
                            "w-[250px] h-[250px] md:h-[324px] md:w-[464px] md:min-w-[464px] relative mx-auto block"
                        }
                    >
                        {item.icon}
                    </span>

                    <h3
                        className={
                            "font-bold lg:text-3xl text-2xl lg:leading-[45px]  mt-10 lg:mt-20"
                        }
                    >
                        {item.title}
                    </h3>
                    <div
                        className={
                            "lg:text-lg text-base lg:leading-8  font-light mt-2"
                        }
                    >
                        {item.description}
                    </div>
                </div>
            ))}
        </section>
    );
}
