import React, { ReactElement, ReactNode } from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
import Header from "@components/shared/header";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "@redux/shared/actions";
import { RootState } from "@redux/reducers";
import ListingModal from "@components/insureyourcar/listingModal";
import { getCookie, setCookie } from "cookies-next";
import { useDomainContext } from "@hooks/useDomainContext";
import style from "./topClinicalTrialsWrapper.module.scss";
import Mobile from "@components/shared/blogPost/sideWidget/mobile";
const Footer = dynamic(() => import("@components/shared/footer"));
const Modal = dynamic(() => import("@components/shared/modal"));
const CloseIcon = dynamic(() => import("@components/svg/closeIcon"));
const TermsAndConditions = dynamic(
    () => import("@components/shared/legal/termsAndConditions"),
);

const PrivacyPolicy = dynamic(
    () => import("@components/shared/legal/privacyPolicy"),
);

export default function TopClinicalTrialsWrapper({
    children,
    state,
    whiteLogo = false,
}: {
    children: ReactNode;
    state?: string | null | undefined;
    whiteLogo?: boolean;
}): ReactElement {
    const dispatch = useDispatch();

    const { clicksOffers: offers, visitDetails } = useDomainContext();

    const handleClose = () => {
        dispatch(setModal({ active: false, content: undefined }));
    };

    const { active: modalActive, content: modalContent } = useSelector(
        (state: RootState) => state.rootReducer.shared.modal,
    );

    const handleMouseLeave = () => {
        if (
            document.activeElement?.tagName === "INPUT" ||
            document.activeElement?.tagName === "SELECT" ||
            !offers?.length ||
            (visitDetails?.trafficSourceType !== "Social Media" &&
                visitDetails?.trafficSourceNetwork !== "Propel Media")
        )
            return;
        if (!getCookie("listing-modal-shown")) {
            dispatch(setModal({ active: true, content: "listingPage" }));
            setCookie("listing-modal-shown", "true");
        }
    };

    const getModalContent = () => {
        switch (modalContent) {
            case "privacy":
                return <PrivacyPolicy />;
            case "terms":
                return <TermsAndConditions />;
            case "listingPage":
                return offers && offers[0] ? (
                    <ListingModal item={offers[0]} state={state} />
                ) : null;
            case "sideWidget":
                return <Mobile />;
            default:
                return null;
        }
    };

    return (
        <>
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap"
                    rel="stylesheet"
                />
            </Head>

            <div
                style={{ fontFamily: `'Poppins', sans-serif` }}
                onMouseLeave={handleMouseLeave}
            >
                <Header
                    backGroundColor="transparent"
                    textColor={whiteLogo ? "#FFFFFF" : "#000"}
                    logoClassName={whiteLogo ? style["logoClassName"] : ""}
                />
                <main className="max-w-7xl mx-auto block px-4vw lg:px-4">
                    {children}
                </main>
                <Footer textColor="#696868" />
                <Modal
                    onClose={handleClose}
                    active={modalActive}
                    closeIcon={<CloseIcon />}
                    backdropClosable
                    modalClassName={
                        modalContent === "listingPage"
                            ? undefined
                            : "h-[90vh] rounded"
                    }
                    modalStyles={
                        modalContent === "listingPage"
                            ? {
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "30px 20px",
                                  paddingBottom: "5px",
                                  fontFamily: `'Poppins', sans-serif`,
                                  borderRadius: "10px",
                              }
                            : {
                                  display: "flex",
                                  flexDirection: "column",
                                  maxHeight:
                                      modalContent === "sideWidget"
                                          ? "80vh"
                                          : "100%",
                              }
                    }
                    bodyStyles={{ overflowY: "scroll" }}
                    width={modalContent === "listingPage" ? 500 : 900}
                    header={
                        modalContent === "privacy"
                            ? "Privacy Policy"
                            : modalContent === "terms"
                            ? "Terms and Conditions"
                            : modalContent === "sideWidget"
                            ? "Contents"
                            : undefined
                    }
                >
                    {getModalContent()}
                </Modal>
            </div>
        </>
    );
}
