import React from "react";

function Diversity() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 85 85"
        >
            <circle cx="42.5" cy="42.5" r="42.5" fill="#5DB0E2"></circle>
            <path
                fill="#fff"
                d="M31.497 21C25.713 21 21 25.642 21 31.36c0 5.72 4.713 10.36 10.497 10.36C37.282 41.72 42 37.08 42 31.36 42 25.642 37.282 21 31.497 21zm14.836 1.045a.745.745 0 00-.748.742v17.146a.745.745 0 00.748.742h17.13a.745.745 0 00.741-.742V22.787a.745.745 0 00-.742-.742H46.333zm-14.836.445c4.991 0 9.012 3.969 9.012 8.87 0 4.902-4.021 8.87-9.012 8.87-4.99 0-9.007-3.968-9.007-8.87 0-4.901 4.017-8.87 9.007-8.87zm15.578 1.045h15.64V39.19h-15.64V23.534zM35.151 44.046l-7.358.02a.744.744 0 00-.525.22l-5.191 5.222a.746.746 0 00-.211.52l.02 7.365a.745.745 0 00.21.527l5.218 5.196a.746.746 0 00.531.22l7.358-.02a.745.745 0 00.524-.22l5.19-5.222a.746.746 0 00.222-.524l-.02-7.364a.744.744 0 00-.221-.533l-5.216-5.189a.742.742 0 00-.531-.218zm19.744.655a.745.745 0 00-.666.411L45.67 62.26a.745.745 0 00.664 1.077h17.13a.745.745 0 00.665-1.077L55.56 45.112a.744.744 0 00-.665-.411zm-20.046.838l4.78 4.753.019 6.757-4.76 4.776-6.743.018-4.77-4.764-.017-6.74 4.75-4.784 6.741-.016zm20.046 1.57l7.358 14.737H47.538l7.357-14.737z"
            ></path>
        </svg>
    );
}

export default Diversity;
