import React from "react";

function Pediatrics() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 62 62"
        >
            <path
                stroke="#5DB0E2"
                strokeDasharray="10 10"
                strokeLinecap="square"
                d="M3.5 3.5H57.5V57.5H3.5z"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M27.629 16.151a.675.675 0 01.961.074c.494.583 2.26 2.077 5.003 2.815 3.592.967 7.787 3.018 9.567 7.942 2.157.556 3.824 2.745 3.824 5.018a5.31 5.31 0 01-1.271 3.485c-.536.629-1.175 1.083-1.72 1.301-1.266 3.255-5.379 9.23-12.866 9.23-7.505 0-11.439-5.425-12.58-8.384l-.083-.049c-1.179-.697-3.448-2.038-3.448-5.131 0-1.632.544-2.712 1.276-3.446.583-.586 1.277-.93 1.73-1.154.452-1.122 1.287-2.722 2.464-4.218 1.266-1.609 2.98-3.163 5.099-3.813a.68.68 0 01.85.461.693.693 0 01-.455.863c-1.749.537-3.249 1.854-4.428 3.352-1.174 1.492-1.979 3.1-2.353 4.103a.688.688 0 01-.328.37c-.092.048-.186.095-.28.142-.455.227-.926.46-1.34.877-.462.463-.871 1.184-.871 2.463 0 2.266 1.576 3.215 2.782 3.94.1.061.2.12.294.179a.69.69 0 01.288.36c.894 2.546 4.436 7.701 11.383 7.701 6.913 0 10.686-5.733 11.701-8.637a.685.685 0 01.514-.448c.22-.042.8-.335 1.339-.966.515-.605.94-1.465.94-2.58 0-1.765-1.423-3.48-3.08-3.737a.685.685 0 01-.545-.466c-1.483-4.544-5.247-6.477-8.753-7.421-3.01-.81-5.014-2.456-5.687-3.25a.698.698 0 01.073-.976z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#5DB0E2"
                fillRule="evenodd"
                d="M28.288 37.297c.374 0 .678.26.678.581 0 .258.16.535.523.768.363.233.895.395 1.511.395.616 0 1.148-.162 1.511-.395s.523-.51.523-.768c0-.32.304-.581.678-.581.375 0 .679.26.679.581 0 .706-.447 1.3-1.066 1.698-.62.398-1.443.627-2.325.627-.882 0-1.706-.229-2.325-.627-.62-.398-1.066-.992-1.066-1.698 0-.32.304-.581.678-.581z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Pediatrics;
