import React, { ReactElement } from "react";
import style from "./style.module.scss";
import MatchTrials from "../matchTrials";
import WhyTrials from "../whyTrials";
export default function MatchTrialsAndWhyTrialsContainer(): ReactElement {
    return (
        <section className={`${style["bgImg"]}`}>
            <div className="pt-12 lg:pt-24 pb-24 lg:pb-48">
                <MatchTrials />
                <WhyTrials />
            </div>
        </section>
    );
}
